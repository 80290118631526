import { index } from 'd3'
import { callApi } from '../../../helpers/fetch.js'
import { loadDataAlaSQL } from './alasql.js'
import { valuePropertyToUse } from './statement-layout/value-property-to-use.js'
import { consolidationOrg } from './compute/consolidation-data.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { isUUID } from '../../../../helpers/helpers/check-types.js'

export async function loadDataStatements (context) {
  console.log('loadDataStatements', context)
  let includedEntityIds = context.includedEntityIds
  if (!includedEntityIds) return
  if (includedEntityIds.length === 0) return

  if (includedEntityIds.includes(consolidationOrg.id)) {
    includedEntityIds = includedEntityIds.filter(id => id !== consolidationOrg.id)
    includedEntityIds.push(`conso-${context.accountData._id}`)
  }

  const promises = []
  includedEntityIds.forEach(function (orgId) {
    promises.push(loadOneEntity(orgId, context))
  })
  const loadedData = await Promise.all(promises)
  console.log('loadDataStatements loadedData', loadedData)

  return loadedData[0]
}

const LOADED_DATA = new Map()

async function loadOneEntity (orgId, context) {
  console.log('loadOneEntity', orgId)
  let mongoOrgId = orgId
  if (isUUID(orgId)) {
    const pgAccountingOrganisation = context.entities.find(e => e.id === orgId)
    const mongoOrg = context.accountData.organisations.find(o => o.orgXeroId === pgAccountingOrganisation.xero_id)
    mongoOrgId = mongoOrg?._id
  }
  if (!mongoOrgId) return

  // We always load data in a specific currency, so that we can cache based on the endpoint url
  const mongoOrg = context.accountData.organisations.find(o => o._id === mongoOrgId)
  const targetCurrencyCode = context.currencySymbol === 'XXX' ? mongoOrg.baseCurrency : context.currencySymbol

  const apiEndpoint = '/api/finlogic/data/:orgId/pg-statement-values/:monthFrom/:monthTo/:currencyCode'
  const url = fillString(apiEndpoint, {
    orgId: mongoOrgId,
    monthFrom: context.dates?.min,
    monthTo: context.dates?.max,
    currencyCode: targetCurrencyCode
  })

  if (LOADED_DATA.has(url)) {
    return LOADED_DATA.get(url)
  }

  const loadedData = await callApi(url)
  console.log('loadDataStatements loadedData', loadedData)
  LOADED_DATA.set(url, loadedData)

  // const mongoEntities = index(context.accountData.organisations, d => d._id)
  // const dataEntity = context.accountData.organisations.find(d => d._id === orgId)

  // Enrich the values with the chart of account
  const chartOfAccountsMap = index(loadedData.convertedValuesResults.chartOfAccounts, d => d.id)
  loadedData.convertedValuesResults?.convertedValues.forEach(function (value) {
    const chartOfAccount = chartOfAccountsMap.get(value.accounting_chart_of_account_id)
    value.chartOfAccount = chartOfAccount

    // value.entity = {
    //   mongoId: dataEntity._id,
    //   xeroId: dataEntity.orgXeroId,
    //   baseCurrency: dataEntity.baseCurrency,
    //   name: dataEntity.name
    // }
  })

  // Enrich data with the period and balance values
  loadedData.convertedValuesResults?.convertedValues.forEach(function (value) {
    const valueFxConversionProperty = value.chartOfAccount?.settings?.fxConversion

    value.forPeriod = value[valuePropertyToUse('period', valueFxConversionProperty)]
    value.forPeriodProperty = valuePropertyToUse('period', valueFxConversionProperty)
    value.forBalance = value[valuePropertyToUse('balance', valueFxConversionProperty)]
    value.forBalanceProperty = valuePropertyToUse('balance', valueFxConversionProperty)

    if (!value.forPeriodProperty) {
      console.warn('no property? forPeriodProperty', value)
    }
  })

  loadDataAlaSQL(loadedData.convertedValuesResults?.convertedValues)

  return loadedData
}
