import { callApi } from '../helpers/fetch.js'

//
export async function loadChartOfAccounts (accountId) {
  const apiUrl = '/api/xero/chart-of-accounts-pg/:accountId/'
  const data = await callApi(apiUrl, {
    accountId
  })
  console.log(data)

  return data
}
