import { createGrid } from 'ag-grid-community'
import { hideElement, showElement, showLoading } from '../../../helpers/dom.js'
import { callApi } from '../../../helpers/fetch.js'
import { appAccount } from '../../../modules/user/account.js'
import { makeColumnDefs } from './make-columndefs.js'
import { makeRows } from './make-rows.js'

export async function updateView () {
  console.log('updateView')

  showLoading('loadingMessage')
  showElement('loadingMessage')

  hideElement('action-export')

  const accountData = appAccount.accountData()

  const groupTransactionsResults = await loadGroupTransactions(accountData)
  console.log('groupTransactionsResults', groupTransactionsResults)
  console.log('groupTransactionsResults.groupTransactions[0]', groupTransactionsResults.groupTransactions[0])

  const context = {
    groupTransactions: groupTransactionsResults.groupTransactions,
    crossContacts: groupTransactionsResults.crossContacts,
    accountData
  }

  showGrid(context)

  hideElement('loadingMessage')
}

function showGrid (context) {
  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)

  const gridRows = makeRows(context)
  const gridColumns = makeColumnDefs(context)
  console.log('gridRows', gridRows)
  console.log('gridColumns', gridColumns)

  const gridOptions = {
    rowData: gridRows,
    columnDefs: gridColumns,
    defaultColDef: {
      filter: true,
      floatingFilter: true,
      onCellClicked: function (cellParams) {
        console.log('cellParams', cellParams)
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
    // suppressMovableColumns: true,
    processUnpinnedColumns: function (params) {
      // Avoid AgGrid from shrinking the columns and moving pinned columns out of the pinned section (if it does, it changes the order of the columns)
      const { columnWidth } = params
      return columnWidth
    },
    context
  }

  const gridApi = createGrid(gridContainerElement, gridOptions)
  return gridApi
}

async function loadGroupTransactions (accountData) {
  console.log('loadGroupTransactions', accountData)
  const apiUrl = '/api/xero/:accountId/group-transactions'
  const data = await callApi(apiUrl, {
    accountId: accountData._id
  })

  return data
}
