import { ascending } from 'd3'

export function makeRows (entitiesResponse) {
  if (!entitiesResponse) return []
  if (!Array.isArray(entitiesResponse)) return []
  if (entitiesResponse.length === 0) return []

  entitiesResponse.sort(function (a, b) {
    return ascending(a.name, b.name)
  })

  return entitiesResponse
}
