import { callApi } from '../../helpers/fetch.js'

//
export async function loadTrackings (mongoAccountId) {
  const apiUrl = '/api/finlogic/data/:accountId/pg-trackings'
  const data = await callApi(apiUrl, {
    accountId: mongoAccountId
  })
  console.log('api data', data)

  return data
}
