import { fillString } from '../../../../helpers/templating/string-template.js'
import { makeXeroLink } from '../../../helpers/xero-links.js'
import { makeSourceName } from './make-source-name.js'

/**
 * @param {Object} entryData
 * @param {Object} sourceData
 * @param {String} xeroShortcode
 */
export function linkToSource (entryData, sourceData, xeroShortcode) {
  console.log('linkToSource', sourceData, xeroShortcode)
  const sourceType = sourceData?.xero_source_type
  const sourceId = sourceData?.xero_id

  if (!sourceId) {
    // console.log('linkToSource, no sourceId:', sourceData)
    return sourceType
  }

  if (sourceData.type_api === 'BankTransfer') {
    return forTransfer(entryData, sourceData, xeroShortcode)
  }

  const xeroSourceType = sourceData.xero_source_type
  let xeroLinkType = xeroSourceType
  if (sourceData.type_api === 'ManualJournal') {
    xeroLinkType = sourceData.type_api
  }

  const linkToXero = fullHtmlLink(xeroLinkType, sourceId, xeroShortcode)

  // The full text
  const fullSourceName = makeSourceName(sourceData)
  const outputTemplate = `${fullSourceName || sourceData.type_api} [${linkToXero}]`
  return outputTemplate
}

function fullHtmlLink (xeroSourceType, sourceId, xeroShortcode) {
  // The link to Xero
  const urlToXero = makeXeroLink(xeroSourceType, {
    shortCode: xeroShortcode,
    sourceId
  })
  const linkToXeroTemplate = '<a href=":urlToXero" target="_blank">Xero</a>'
  const linkToXero = fillString(linkToXeroTemplate, {
    urlToXero
  })
  return linkToXero
}

function forTransfer (entryData, sourceData, xeroShortcode) {
  console.log('forTransfer', entryData)

  let transferData
  let transferSide = null
  switch (entryData?.accounting_chart_of_account_id) {
    case entryData.bank_transfer_from?.accounting_chart_of_account_id:
      transferData = entryData.bank_transfer_from
      transferSide = 'SEND'
      break
    case entryData.bank_transfer_to?.accounting_chart_of_account_id:
      transferData = entryData.bank_transfer_to
      transferSide = 'RECEIVE'
      break
  }

  console.log(transferSide, transferData)

  const linkToXero = fullHtmlLink('BankTransaction', transferData?.xero_bank_transaction_id, xeroShortcode)
  console.log('linkToXero', linkToXero)

  // The full text
  const outputTemplate = ':transferSide > :sourceName [:linkToXero]'

  const transferText = fillString(outputTemplate, {
    transferSide,
    sourceName: makeSourceName(sourceData),
    linkToXero
  })
  console.log(transferText)
  return transferText
}
