
import * as mUser from '../../modules/user/user.js'
import * as mHtmlParser from '../../modules/user/html-parser.js'

import htmlTemplateIndex from './index.html'
import { appAccount } from '../../modules/user/account.js'
import { appConfig } from '../../config/config.js'
import { generateRandomString } from '../../../helpers/helpers/random.js'
import { fillString } from '../../../helpers/templating/string-template.js'
import { loadEntitiesSyncStats } from './load-entities-sync-stats.js'
import { showTableEntitiesSyncStats } from './show-table.js'
import { loadEntities } from '../../data-helpers/load-entities.js'

/**
 *
 * @returns
 */
export async function showPageDataSyncsPG () {
  console.log('showPageDataSyncsPG')

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(appConfig.contentElementId)
  if (!DOMelement) return

  const accountData = appAccount.accountData()

  document.title = ['Group Entities:', accountData.name].join(' ')

  let htmlPageLayout = mHtmlParser.appHtmlParser.applyRestrictions(htmlTemplateIndex, { accountData })
  const elementId = 'i' + generateRandomString()
  htmlPageLayout = fillString(htmlPageLayout, { elementId })

  // Inject the page in the app container
  DOMelement.innerHTML = htmlPageLayout

  document.getElementById('app-content').classList.add('position-absolute')
  document.getElementById('app-content').classList.add('top-to-bottom')

  const [entitiesSyncStatsResponse, entitiesResponse] = await Promise.all([
    loadEntitiesSyncStats(accountData._id),
    loadEntities(accountData._id)
  ])

  showTableEntitiesSyncStats(accountData, entitiesSyncStatsResponse, entitiesResponse)
}
