import { callApi } from '../../helpers/fetch.js'

//
export async function loadEntitiesSyncStats (mongoAccountId) {
  const apiUrl = '/api/sync-pg/stats/:accountId'
  const data = await callApi(apiUrl, {
    accountId: mongoAccountId
  })
  console.log('api data', data)

  return data
}
