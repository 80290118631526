
// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mHtmlParser from '../../modules/user/html-parser.js'

// Page Module
import htmlTemplateIndex from './index.html'
import { appAccount } from '../../modules/user/account.js'
import { appConfig } from '../../config/config.js'
import { generateRandomString } from '../../../helpers/helpers/random.js'
import { fillString } from '../../../helpers/templating/string-template.js'
import { callApi } from '../../helpers/fetch.js'

/**
 *
 * @returns
 */
export async function showPageCopyPgFromMongo () {
  console.log('showPageCopyPgFromMongo')

  if (mUser.enforceRestrictedPage({ allowPublic: false })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(appConfig.contentElementId)
  if (!DOMelement) return

  const accountData = appAccount.accountData()

  document.title = ['Admin: copy:', accountData.name].join(' ')

  let htmlPageLayout = mHtmlParser.appHtmlParser.applyRestrictions(htmlTemplateIndex, { accountData })
  const elementId = 'i' + generateRandomString()
  htmlPageLayout = fillString(htmlPageLayout, { elementId })

  // Inject the page in the app container
  DOMelement.innerHTML = htmlPageLayout

  await callCopyApi(accountData._id)
}
export async function callCopyApi (accountId) {
  const apiUrl = '/api/xero/copy-pg-from-mongo/:accountId'
  const data = await callApi(apiUrl, {
    accountId
  })
  console.log(data)

  return data
}
