import { rollup } from 'd3'

/**
 * ACTUALLY TO BE USED FRONT END
 * @param {*} layoutSettings
 * @param {*} chartOfAccounts
 * @returns
 */
export function convertToFullLayout (layoutSettings, chartOfAccounts) {
  // console.log('convertToFullLayout', layoutSettings, chartOfAccounts)

  const fullLayout = {
    rows: []
  }

  layoutSettings.rows.forEach(function (rowSettings) {
    const row = {
      id: rowSettings.id,
      label: rowSettings.label,
      type: rowSettings.type,
      includes: rowSettings.includes,
      viewMultiplier: rowSettings.viewMultiplier,
      expectedSign: rowSettings.expectedSign,
      formulaAlaSQL: rowSettings.formulaAlaSQL,
      formulaAlaSQLOperator: rowSettings.formulaAlaSQLOperator,
      formatting: rowSettings.formatting
    }

    if (rowSettings.includes) {
      const matchingChartOfAccounts = chartOfAccounts.filter(function (chartOfAccount) {
        const matchesIncludes = rowSettings.includes.types?.includes(chartOfAccount.xero_type) || rowSettings.includes.id?.includes(chartOfAccount.id)

        if (!matchesIncludes) return false

        // Exclude Xero RETAINEDEARNINGS: we compute these directly
        if (chartOfAccount.system_account === 'RETAINEDEARNINGS') return false

        return true
      })

      // Combine accounts by type + name
      const combinedAccounts = rollup(matchingChartOfAccounts,
        function (chartOfAccounts) {
          return {
            id: accountKey(chartOfAccounts[0]),
            label: chartOfAccounts[0].name,
            context: {
              chartOfAccounts
            },
            viewMultiplier: rowSettings.viewMultiplier,
            expectedSign: rowSettings.expectedSign
          }
        },
        accountKey)
      // logger.debug(['combinedAccounts', combinedAccounts])

      row.aggregation = 'sum'
      row.children = Array.from(combinedAccounts.values())
    }

    fullLayout.rows.push(row)
  })

  return fullLayout
}

function accountKey (account) {
  return [account.xero_type, account.name].join(' + ')
}
