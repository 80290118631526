import { formatNumber } from '../../../../helpers/helpers/numbers.js'

export function makeColumnDefs (context) {
  console.log('makeColumnDefs', context)
  const columnDefs = []

  // Chart of Account Code
  columnDefs.push({
    headerName: 'Id',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.accounting_chart_of_account_id
    },
    width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Statement',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.statement
    },
    width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Type',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.xero_type
    },
    width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'FX',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.settings?.fxConversion
    },
    width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.code
    },
    width: 90,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      return cellParams.data.chartOfAccount?.name
    },
    width: 200,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Date',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.month
    },
    width: 120,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Local Period',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.basePeriod
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Local Balance',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.baseBalance
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Period',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.valuePeriod
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Period Cumul',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.valuePeriodsCumul
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Balance Change',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.valueBalanceChange
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Balance',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.valueBalance
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Revaluation Change',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.valueRevaluationChange
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Revaluation Balance',
    valueGetter: function (cellParams) {
      return cellParams.data.value?.valueRevaluationBalance
    },
    valueFormatter: formatter,
    cellClass: styling,
    type: 'numericColumn',
    width: 120
  })

  return columnDefs
}

function formatter (cellParams) {
  if (!cellParams.value) return

  const context = cellParams.context

  const decimals = context?.viewDecimals || 2
  return formatNumber(cellParams.value, decimals)
}

function styling (cellParams) {
  const classes = ['right', 'number']
  if (!cellParams.value) {
    classes.push('zero')
  }
  return classes
}
