import { GRID_METHOD } from './make-grid-data.js'

export function makeRowPinnedTop (gridApi) {
  // const visibleRows = listOfVisibleRows(gridApi)

  const pinnedRows = []

  if (GRID_METHOD === 'byAllAccounts') {
    pinnedRows.push({
      name: 'Total'
    })
  }

  return pinnedRows
}

export function listOfVisibleRows (gridApi) {
  const visibleRows = []

  gridApi?.forEachNodeAfterFilterAndSort(function (rowNode) {
    // console.log('rowNode', rowNode)
    visibleRows.push(rowNode.data)
  })

  console.log('visibleRows', visibleRows)
  return visibleRows
}
