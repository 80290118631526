
/**
 * Find the organisation by its name, legalName, apiKey or _id
 * @param {*} organisations
 * @param {String} targetString
 * @returns
 */
export function accessOrganisation (organisations, targetString) {
  const organisation = organisations?.find(function (one) {
    if (one._id === targetString) return true
    if (one.xeroNetworkKey === targetString) return true
    if (one.name === targetString) return true
    if (one.legalName === targetString) return true

    return false
  })

  return organisation
}
