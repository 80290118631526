import { optionSelectorData } from '../data.js'
import { getOrgId } from './get-org-id.js'

export function recordSelection (targetOrgId, params) {
  console.log('recordSelection', targetOrgId, optionSelectorData)
  optionSelectorData.organisationId = optionSelectorData.organisationId || []

  if (targetOrgId === 'all') {
    toggleAll()
  } else if (targetOrgId === 'none') {
    toggleNone()
  } else if (targetOrgId === 'conso') {
    optionSelectorData.organisationId = ['conso']
  } else if (targetOrgId === params.customSettings?.orgOptions?.enableConsolidation?.id) {
    optionSelectorData.organisationId = [targetOrgId]
  } else {
    if (params.customSettings?.orgOptions?.allowMultiple || params.customSettings?.orgOptions?.allowSeveral) {
      optionSelectorData.organisationId = optionSelectorData.organisationId || []

      if (optionSelectorData.organisationId.includes('conso')) {
        optionSelectorData.organisationId = optionSelectorData.organisationId.filter(id => id !== 'conso')
      }

      if (optionSelectorData.organisationId.includes(params.customSettings?.orgOptions?.enableConsolidation?.id)) {
        optionSelectorData.organisationId = optionSelectorData.organisationId.filter(id => id !== params.customSettings?.orgOptions?.enableConsolidation?.id)
      }

      if (!optionSelectorData.organisationId.includes(targetOrgId)) {
        optionSelectorData.organisationId.push(targetOrgId)
      } else {
        optionSelectorData.organisationId = optionSelectorData.organisationId?.filter(id => id !== targetOrgId)
      }
    } else {
      optionSelectorData.organisationId = targetOrgId
    }
  }
}

function toggleAll () {
  optionSelectorData.organisationId = []
  optionSelectorData.orgsArray.forEach(function (org) {
    const orgId = getOrgId(org)
    if (['all', 'none', 'conso'].includes(orgId)) return
    if (org.type === 'break') return

    optionSelectorData.organisationId.push(orgId)
  })
}

function toggleNone () {
  optionSelectorData.organisationId = []
}
