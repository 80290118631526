import { createGrid } from 'ag-grid-community'
import { makeColumnDefs } from './make-column-defs.js'
import { makeRows } from './make-rows.js'
import { addListeners } from './listeners.js'

export function showTableEntitiesSyncStats (accountData, entitiesSyncStatsResponse, entitiesResponse) {
  console.log('showTableEntitiesSyncStats', entitiesResponse)

  const rows = makeRows(entitiesResponse, entitiesSyncStatsResponse)
  console.log('rows', rows)

  const columnDefs = makeColumnDefs(accountData, entitiesResponse)
  console.log('columnDefs', columnDefs)

  const context = {
    accountingOrganisations: entitiesResponse
  }

  const gridOptions = {
    rowData: rows,
    columnDefs,
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
    getRowId: (cellParams) => cellParams.data.id,
    onCellClicked: function (cellParams) {
      console.log('onCellClicked', cellParams)
    },
    context
  }

  const containerId = 'dataGrid'
  const gridContainerElement = document.getElementById(containerId)
  console.log('gridContainerElement', gridContainerElement)
  const gridApi = createGrid(gridContainerElement, gridOptions)
  console.log('grid created', gridApi)

  addListeners(gridApi, gridContainerElement, context)
}
