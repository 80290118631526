import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { activateOptionSelector } from '../../../modules/option-selector/index.js'
import { appAccount } from '../../../modules/user/account.js'

export function showSelectors (changeAction) {
  console.log('showSelectors')

  const optionSelectorShow = [
    'dates',
    'decimals',
    'optionsCurrencies',
    'optionsGroupBy'
  ]

  const accountData = appAccount.accountData()
  const dateseries = makeDateSerie(accountData.firstDate)

  const optionSelectorParams = activateOptionSelector({
    containerId: 'optionSelector',
    show: optionSelectorShow,
    hide: ['currencies'],
    default: 'organisations',
    onChange: changeAction,
    dateseries,
    modules: {
      optionCurrencies: {
        currencies: accountData?.currencies,
        default: 'XXX'
      },
      optionsGroupBy: {
        options: [
          {
            label: 'Contacts',
            value: 'contactKey'
          },
          {
            label: 'Accounts',
            value: 'accountKey'
          },
          {
            label: 'Entities',
            value: 'entityKey'
          }
        ],
        default: ['contactKey']
      }
    },
    dateOptions: {
      default: {
        from: 'relative:-6',
        to: 'relative:0'
      }
    },
    customSettings: {
      organisations: mapOrganisations(),
      orgOptions: {
        allowConso: false,
        allowMultiple: true,
        allowSeveral: true
        // defaultIndex: 0
      }
    }
  })
  return optionSelectorParams
}

function mapOrganisations () {
  const accountData = appAccount.accountData()
  // console.log('accountData', accountData)

  const organisations = accountData?._accountOrganisations?.map(function (accountOrganisation) {
    return accountOrganisation._xeroOrganisation
  })
  return organisations
}
