import { callApi } from '../helpers/fetch.js'

//
export async function loadChartOfAccountsWithStats (accountId) {
  const apiUrl = '/api/xero/chart-of-accounts-pg/:accountId/true'
  const data = await callApi(apiUrl, {
    accountId
  })
  console.log(data)

  return data
}
