
/**
 *
 * @param {Object} sourceData
 * @param {String} sourceData.documentNumber
 * @param {String} sourceData.reference
 * @returns
 */
export function makeSourceName (sourceData) {
  const referenceTrunc = 15

  let truncatedRef = (sourceData.reference || '').substring(0, referenceTrunc)
  if (sourceData.reference?.length > referenceTrunc) truncatedRef = truncatedRef + '(...)'

  const sourceName = [sourceData.documentNumber, truncatedRef].join(' ')

  if (!sourceName) return 'No reference'

  return sourceName
}
