import { ascending, descending } from 'd3'
import dayjs from 'dayjs'
import { formatNumber } from '../../../../../helpers/helpers/numbers.js'
import { makeMonthSeries } from '../month-series.js'
import { computeCell } from './compute-cell.js'
import { byLayout } from './by-layout.js'
import { isUUID } from '../../../../../helpers/helpers/check-types.js'
import { consolidationOrg } from '../compute/consolidation-data.js'

export function makeLayoutColumnDefs (context) {
  const columnDefs = []

  // Chart of Account Code
  columnDefs.push({
    headerName: ' ',
    valueGetter: function (cellParams) {
      if (cellParams.data.type === 'break') return null

      const padding = ' '.repeat(cellParams.data.level * 3) // space is trimmed by AgGrid, could use '\u00A0' instead; or the cellStyle: { whiteSpace: 'pre' }
      return padding + cellParams.data.label
    },
    cellRenderer: function (cellParams) {
      if (cellParams.data.children) {
        const icon = context.grid.openedGroups.has(cellParams.data.id) ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'
        return '<i class="' + icon + '"></i> ' + cellParams.value
      }
      return cellParams.value
    },
    cellStyle: { whiteSpace: 'pre' },
    cellClass: rowStyleClasses,
    onCellClicked: function (cellParams) {
      if (!cellParams.data.children) return

      if (context.grid.openedGroups.has(cellParams.data.id)) {
        context.grid.openedGroups.delete(cellParams.data.id)
      } else {
        context.grid.openedGroups.add(cellParams.data.id)
      }

      const columnDefs = cellParams.api.getColumnDefs()
      cellParams.api.setGridOption('rowData', byLayout(context, columnDefs))
      // Refresh the cell to show the updated icon
      cellParams.api.refreshCells({
        rowNodes: [cellParams.node],
        columns: [cellParams.colDef],
        force: true
      })
    },
    width: 200,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'FX Applied',
    valueGetter: function (cellParams) {
      if (cellParams.data.type === 'break') return null
      return cellParams.data.fxConversion
    },
    cellClass: rowStyleClasses,
    width: 100,
    pinned: 'left'
  })

  // Add the months columns
  columnDefs.push(...makeContentColumns(context))
  console.log('columnDefs', columnDefs)

  return columnDefs
}

/**
 *
 * @param {*} context
 * @returns
 */
function makeContentColumns (context) {
  console.log('makeContentColumns', context)

  const monthsArray = makeMonthSeries(context)
  // console.log('monthsArray', monthsArray)

  monthsArray?.sort(function (m1, m2) {
    if (context.timeDirection === 'descending') {
      return descending(m1, m2)
    } else {
      return ascending(m1, m2)
    }
  })

  const entitiesSeries = makeEntitiesSeries(context)
  // console.log('entitiesSeries', entitiesSeries)

  const layoutArrays = []
  if (context.entitiesLayout === 'detailedDateEntity' || context.entitiesLayout === 'consolidated') {
    layoutArrays.push(monthsArray)
    layoutArrays.push(entitiesSeries)
  } else if (context.entitiesLayout === 'detailedEntityDate') {
    layoutArrays.push(entitiesSeries)
    layoutArrays.push(monthsArray)
  }
  // console.log('context.entitiesLayout', context.entitiesLayout, 'layoutArrays', layoutArrays)

  const contentColDefs = []

  layoutArrays[0].forEach(function (level0Item) {
    const headerName = ['consolidated', 'detailedDateEntity'].includes(context.entitiesLayout) ? makeColumnDate(context, level0Item) : level0Item.name
    const colDefLevel1 = {
      headerName
    }

    if (context.entitiesLayout !== 'consolidated') {
      colDefLevel1.children = []
      colDefLevel1.columnGroupShow = 'open'

      contentColDefs.push(colDefLevel1)
    }

    layoutArrays[1]?.forEach(function (level1Item) {
      const columnParams = {
        isValue: true,
        monthDate: ['consolidated', 'detailedDateEntity'].includes(context.entitiesLayout) ? level0Item : level1Item,
        accountingOrganisation: ['consolidated', 'detailedDateEntity'].includes(context.entitiesLayout) ? level1Item : level0Item
      }
      const colDef = colDefValueSettings(context, columnParams)

      if (context.entitiesLayout === 'consolidated') {
        const singleLevelColDef = Object.assign(colDefLevel1, colDef)

        contentColDefs.push(singleLevelColDef)
      } else {
        const headerName = context.entitiesLayout === 'detailedDateEntity' ? level1Item.name : makeColumnDate(context, level1Item)

        const colDefLevel2 = {
          headerName,
          ...colDef
        }

        colDefLevel1.children.push(colDefLevel2)
      }
    })
  })

  console.log('contentColDefs', contentColDefs)
  return contentColDefs
}

function makeEntitiesSeries (context) {
  const entitiesSeries = []
  if (context.showingEntitiesIds.length > 1) {
    context.showingEntitiesIds.forEach(function (showingEntityId) {
      const accountingOrganisation = findAccountingOrganisation(context, showingEntityId)
      entitiesSeries.push(accountingOrganisation)
    })
  } else {
    const targetOrgId = context.showingEntitiesIds[0]
    const shownAccountingOrganisation = findAccountingOrganisation(context, targetOrgId)
    entitiesSeries.push(shownAccountingOrganisation)
  }

  return entitiesSeries
}

function findAccountingOrganisation (context, targetOrgId) {
  // console.log('findAccountingOrganisation', context, targetOrgId)
  if (targetOrgId === consolidationOrg.id) {
    return consolidationOrg
  }

  if (isUUID(targetOrgId)) {
    const shownAccountingOrganisation = context.entities.find(o => o.id === targetOrgId)
    return shownAccountingOrganisation
  }

  const mongoShownEntity = context.accountData?.organisations?.find(o => o._id === targetOrgId)
  console.log('mongoShownEntity', mongoShownEntity, mongoShownEntity?.orgXeroId)

  const shownAccountingOrganisation = context.entities.find(o => o.xero_id === mongoShownEntity?.orgXeroId)
  console.log('shownAccountingOrganisation', shownAccountingOrganisation)
}

function makeColumnDate (context, monthDate) {
  if (context.statementType === 'bs') {
    return dayjs(monthDate).endOf('month').format('DD MMM YYYY')
  } else {
    return dayjs(monthDate).format('MMM YYYY')
  }
}

function colDefValueSettings (context, columnParams) {
  const colDef = {
    valueGetter: function (cellParams) {
      if (cellParams.data.type === 'break') return null

      const cellValue = computeCell(cellParams)
      return cellValue
    },
    valueFormatter: function (cellParams) {
      if (!cellParams.value) return

      if (cellParams.data.formatting?.type === 'percentage') {
        const decimals = cellParams.data.formatting?.decimals || 0
        const percentageValue = cellParams.value * 100
        return formatNumber(percentageValue, decimals) + '%'
      }

      const decimals = Number.isFinite(context.viewDecimals) ? context.viewDecimals : 2
      return formatNumber(cellParams.value, decimals)
    },
    context: columnParams,
    type: 'numericColumn',
    cellClass: function (cellParams) {
      const rowStyles = rowStyleClasses(cellParams)

      const classes = [...rowStyles, 'right', 'number']
      if (!cellParams.value) {
        classes.push('zero')
      }
      return classes
    },
    onCellClicked: function (cellParams) {
      console.log('colDef onCellClicked', cellParams)
      cellParams.context.showDebug = true
      computeCell(cellParams)
      rowStyleClasses(cellParams)
      cellParams.context.showDebug = false
    },
    width: 130

  }
  return colDef
}

function rowStyleClasses (cellParams) {
  const classes = []

  if (cellParams.data.formulaAlaSQLOperator === 'divide') {
    classes.push('fs-ratio')
  } else if (cellParams.data.formulaAlaSQL) {
    classes.push('fs-sum')
  }

  if (cellParams.data.level === 1) {
    classes.push('fs-details')
  }

  if (cellParams.context.showDebug) {
    console.log('rowStyleClasses', classes)
  }
  return classes
}
