import { ascending } from 'd3'
import { appAccount } from '../../../modules/user/account.js'
import dayjs from 'dayjs'
import { computeIntercoTotals } from './compute-interco-total.js'

export function makeRows (context) {
  const groupTransactions = context?.groupTransactions

  // Enrich the transactions
  groupTransactions.forEach(function (transaction) {
    transactionEntitiesPair(context, transaction)
    transaction.intercoTransactionId = transactionGroupId(transaction)
  })

  const intercoTransactionTotals = computeIntercoTotals(groupTransactions)
  console.log(intercoTransactionTotals)

  groupTransactions?.sort(function (t1, t2) {
    return ascending(t1.journalDate, t2.journalDate) || ascending(Math.abs(t1.value), Math.abs(t2.value))
  })

  return groupTransactions
}

/**
 * Enrich with the entities data
 * @param {*} context
 * @param {*} transaction
 * @returns
 */
function transactionEntitiesPair (context, transaction) {
  const entitiesPair = []
  transaction.entitiesPair = entitiesPair

  const orgId = transaction._xeroOrganisation
  const transactionOrg1 = appAccount.getOrganisationData(orgId)
  entitiesPair.push(transactionOrg1)
  transaction.recordingEntity = transactionOrg1

  const transactionContactId = transaction._contact
  const contact = context.crossContacts.find(contact => contact._id === transactionContactId)
  const contactOrgNetworkKey = contact?.xeroNetworkKey
  const transactionOrg2 = appAccount.accessOrganisation(contactOrgNetworkKey || contact?.name)
  entitiesPair.push(transactionOrg2)

  entitiesPair.sort(function (a, b) {
    return ascending(a.name, b.name)
  })

  transaction.entitiesPairName = [entitiesPair[0]?.name, entitiesPair[1]?.name].sort().join(' <> ')

  return transaction
}

/**
 * Transaction for interco should mean that for a date + Entity1 + Entity2 the total are net 0
 * @param {*} transaction
 * @returns
 */
function transactionGroupId (transaction) {
  return [dayjs(transaction.journalDate).format('YYYY-MM-DD'), transaction.entitiesPairName].join(' << ')
}
