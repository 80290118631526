
// view: period or balance
// account fxConversion: period or balance or history
export const propertyChoices = [
  {
    // Eg. PnL lines
    valueType: 'period',
    fxConversion: 'period',
    propertyName: 'valuePeriod'
  },
  {
    // Eg. Equity lines
    valueType: 'period',
    fxConversion: 'history',
    propertyName: 'valuePeriod' // Should actually be different
  },
  {
    valueType: 'period',
    fxConversion: 'balance',
    propertyName: 'valueBalanceChange' // 'valueRevaluationChange'
    // propertyName: 'valueBalanceChange' // 'valueRevaluationChange'
  },

  {
    valueType: 'balance',
    fxConversion: 'period',
    propertyName: 'valuePeriodsCumul'
  },
  {
    valueType: 'balance',
    fxConversion: 'history',
    propertyName: 'valuePeriodsCumul' // Should actually be different value
  },
  {
    valueType: 'balance',
    fxConversion: 'balance',
    propertyName: 'valueBalance'
  }
]

export function valuePropertyToUse (valueType, fxConversion) {
  const propertyChoice = propertyChoices.find(d =>
    d.valueType === valueType &&
    d.fxConversion === fxConversion
  )
  return propertyChoice?.propertyName
}
