import dayjs from 'dayjs'
import { makeXeroLink } from '../../helpers/xero-links.js'
import { formatNumber } from '../../../helpers/helpers/numbers.js'
import { menuOptions } from './show-menu.js'

export function makeColumnDefs (chartOfAccountResponse) {
  const groupingProperty = menuOptions.optionGrouping.selectedOption()?.value || 'name'

  const columnDefs = [
    {
      headerName: groupingProperty === 'name' ? 'Account Name' : 'Account Code',
      valueGetter: function (cellParams) {
        return cellParams.data.grouping?.[groupingProperty]
      },
      pinned: 'left'
    }
  ]

  const entitiesColumns = makeEntitiesColumns(chartOfAccountResponse)
  columnDefs.push(...entitiesColumns)
  console.log('columnDefs', columnDefs)

  return columnDefs
}

function makeEntitiesColumns (chartOfAccountResponse) {
  const entitiesColumns = []

  chartOfAccountResponse.accountingOrganisations?.forEach(function (accountingOrganisation) {
    const columnDef = {
      headerName: accountingOrganisation.name,
      width: 180,
      context: {
        accountingOrganisation
      },
      onCellClicked: function (cellParams) {
        console.log(cellParams, cellParams.value)
        // const cellValue = getCellValue(cellParams)
        // console.log('cellValue', cellValue)
      },
      tooltipValueGetter: function (cellParams) {
        if (cellParams.node.rowPinned) return 'Click to open Xero Chart of Accounts page and update as you need'

        const orgValues = getColumnOrgValues(cellParams)
        if (!orgValues) return 'Not used for this entity'

        const orgValue = orgValues[0]
        if (!orgValue) return 'Not used for this entity'

        const firstJournalDate = dayjs(orgValue.first_ledger_date).format('D MMM YYYY')
        const lastJournalDate = dayjs(orgValue.last_ledger_date).format('D MMM YYYY')

        const totalAmountLast90Days = [accountingOrganisation.base_currency, formatNumber(parseFloat(orgValue.total_amount_last_90_days), 0)].join(' ')
        const totalAmount = [accountingOrganisation.base_currency, formatNumber(parseFloat(orgValue.total_amount), 0)].join(' ')

        const totalAmountRecentText = totalAmountLast90Days ? `Total amount last 90 days: ${totalAmountLast90Days} | ` : ''
        const totalAmountText = totalAmount ? `All time: ${totalAmount} | ` : ''
        const firstJournalDateText = firstJournalDate ? `First journal date: ${firstJournalDate} | ` : ''
        const lastJournalDateText = lastJournalDate ? `Latest: ${lastJournalDate}` : ''

        return `${totalAmountRecentText} ${totalAmountText} ${firstJournalDateText} ${lastJournalDateText}`
      },

      valueGetter: function (cellParams) {
        if (cellParams.node.rowPinned) {
          return makeXeroLink('ChartAccounts', {
            shortCode: accountingOrganisation.xero_shortcode
          })
        }

        return getCellValue(cellParams)
      },
      cellRenderer: function (cellParams) {
        if (cellParams.node.rowPinned) {
          const linkToXero = cellParams.value
          // Could even do to ?q=AccountName for specific account
          return `<a href='${linkToXero}' target='_blank'>Open in Xero</a>`
        }

        return cellRenderer(cellParams)
      }
    }

    entitiesColumns.push(columnDef)
  })

  // console.log(entitiesColumns)
  return entitiesColumns
}

function getColumnOrgValues (cellParams) {
  const columnAccountingOrganisationId = cellParams.colDef.context.accountingOrganisation.id
  const orgValues = cellParams.data.valuesByAccountingOrganisationId.get(columnAccountingOrganisationId)

  return orgValues
}

function getCellValue (cellParams) {
  const orgValues = getColumnOrgValues(cellParams)
  if (!orgValues) return null
  if (orgValues.length === 0) return null
  if (orgValues.length > 1) return 'multiple accounts'

  const orgValue = orgValues[0]
  const outputShow = cellParams.context?.menuOptions?.outputShow?.selectedOption()?.value || 'type'

  switch (outputShow) {
    case 'usageRecent':
      return parseFloat(orgValue.total_amount_last_90_days)
    case 'usageTotal':
      return parseFloat(orgValue.total_amount)
    case 'type':
      return orgValue.xero_type
    case 'code':
      return orgValue.code
    case 'name':
      return orgValue.name
  }
}

function cellRenderer (cellParams) {
  const orgValues = getColumnOrgValues(cellParams)
  if (!orgValues) return null
  if (orgValues.length === 0) return null
  if (orgValues.length > 1) return 'multiple accounts'

  const orgValue = orgValues[0]
  const outputShow = cellParams.context?.menuOptions?.outputShow?.selectedOption()?.value || 'type'

  const isArchived = orgValue.active === false
  const isNeverUsed = parseInt(orgValue.number_ledgers) === 0
  const isNotUsedRecently = dayjs(orgValue.last_ledger_date).isBefore(dayjs().subtract(90, 'days'))
  const lastLedgerDate = dayjs(orgValue.last_ledger_date).isBefore(dayjs().subtract(360, 'days')) ? dayjs(orgValue.last_ledger_date).format('MMM YYYY') : dayjs(orgValue.last_ledger_date).format('D MMM')
  const lastLedgerDateTag = dayjs(orgValue.last_ledger_date).isBefore(dayjs().subtract(360, 'days')) ? 'is-warning' : 'is-info'

  const highlights = `
    ${isArchived ? '<span class="tag is-info is-light is-small">Archived</span> ' : ''}
    ${!isArchived && isNeverUsed ? '<span class="tag is-warning is-light is-small">Never used</span> ' : ''}
    ${!isArchived && isNotUsedRecently ? '<span class="tag is-info ' + lastLedgerDateTag + ' is-small">Last on ' + lastLedgerDate + '</span> ' : ''}
  `

  const accountingOrganisation = cellParams.colDef.context.accountingOrganisation

  switch (outputShow) {
    case 'usageRecent':
      return highlights + (Math.abs(parseFloat(orgValue.total_amount_last_90_days)) > 0 ? [accountingOrganisation?.base_currency, formatNumber(parseFloat(orgValue.total_amount_last_90_days), 0)].join(' ') : '')
    case 'usageTotal':
      return highlights + (Math.abs(parseFloat(orgValue.total_amount)) > 0 ? [accountingOrganisation?.base_currency, formatNumber(parseFloat(orgValue.total_amount), 0)].join(' ') : '')
    case 'type':
      return `${highlights} ${orgValue.statement === 'bs' ? 'Balance Sheet' : 'P&L'} ${orgValue.xero_type}`
    case 'code':
      return highlights + `<span class="tag is-info is-light">${orgValue.code ? orgValue.code : '! No Code'}</span> `
    case 'name':
      return highlights + orgValue.name
  }
}
