import { createGrid } from 'ag-grid-community'
import { makeColumnDefs } from './make-column-defs.js'
import { makeRows } from './make-rows.js'

export function showTableCurrencies (accountData, currencyResponse) {
  console.log('showTableCurrencies', currencyResponse)

  const rows = makeRows(currencyResponse)
  console.log('rows', rows)

  const columnDefs = makeColumnDefs(accountData, currencyResponse)
  console.log('columnDefs', columnDefs)

  const gridOptions = {
    rowData: rows,
    columnDefs,
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
    getRowId: (cellParams) => cellParams.data.id,
    onCellClicked: function (cellParams) {
      console.log('onCellClicked', cellParams)
    }
  }

  const containerId = 'currencyGrid'
  const gridContainerElement = document.getElementById(containerId)
  console.log('gridContainerElement', gridContainerElement)
  const gridApi = createGrid(gridContainerElement, gridOptions)
  console.log('grid created', gridApi)
}
