import { callApi } from '../../../../helpers/fetch.js'

export async function syncManagementListeners (clickEvent, orgId) {
  console.log('syncManagementListeners', clickEvent)
  const targetElement = clickEvent.target.closest('.button')
  if (!targetElement) return

  const targetAction = targetElement.getAttribute('data-action')
  const targetOrgId = orgId // targetElement.getAttribute('data-orgId')

  console.log('targetElement', targetElement, targetAction, targetOrgId)

  await actions[targetAction](targetOrgId)
}

const actions = {
  generateCache: async function (orgId) {
    await callApi('/api/finlogic/data/generate/:orgId', {
      orgId
    }, { method: 'GET' })
  },
  deleteCache: async function (orgId) {
    console.log('deprecated')
    // await callApi('/api/finlogic/data/generate/:orgId/:whatToDelete', {
    //   orgId,
    //   options: 2
    // }, { method: 'DELETE' })
  },
  //
  // DELETE ACTIONS:
  deleteAll: async function (orgId) {
    await callApi('/api/finlogic/data/generate/:orgId/:whatToDelete', {
      orgId,
      whatToDelete: 'all'
    }, { method: 'DELETE' })
  },
  deleteJournals: async function (orgId) {
    await callApi('/api/finlogic/data/generate/:orgId/:whatToDelete', {
      orgId,
      whatToDelete: 'journals'
    }, { method: 'DELETE' })
  },
  deleteTrialBalances: async function (orgId) {
    await callApi('/api/finlogic/data/generate/:orgId/:whatToDelete', {
      orgId,
      whatToDelete: 'trialBalances'
    }, { method: 'DELETE' })
  },
  deleteCombinations: async function (orgId) {
    await callApi('/api/finlogic/data/generate/:orgId/:whatToDelete', {
      orgId,
      whatToDelete: 'combinations'
    }, { method: 'DELETE' })
  },
  deleteXeroSources: async function (orgId) {
    await callApi('/api/finlogic/data/generate/:orgId/:whatToDelete', {
      orgId,
      whatToDelete: 'sources'
    }, { method: 'DELETE' })
  },

  stopScheduleSync: async function (orgId) {
    await callApi('/api/xero/sync/:orgId', {
      orgId
    }, { method: 'DELETE' })
  },
  scheduleSync: async function (orgId) {
    await callApi('/api/xero/sync/:orgId', {
      orgId
    }, { method: 'POST' })
  },
  analyseJournalsWithTrackings: async function (orgId) {
    await callApi('/api/finlogic/data/:orgId/analyse-journals-with-trackings', {
      orgId
    }, { method: 'GET' })
  }
}
