import { group, rollup } from 'd3'

export function makeRows (chartOfAccountResponse, menuOptions) {
  if (!chartOfAccountResponse?.chartOfAccounts) return []

  const groupingProperty = menuOptions.optionGrouping.selectedOption()?.value || 'name'

  const chartsOfAccounts = chartOfAccountsToInclude(chartOfAccountResponse?.chartOfAccounts, menuOptions)

  const groupedMap = rollup(chartsOfAccounts,
    function (groupValues) {
      // Indexing can break when there are missing values, example code=null might have multiple accounts for an entity
      const grouped = group(groupValues, g => g.accounting_organisation_id)

      return {
        grouping: {
          [groupingProperty]: formatGroupingText(groupValues[0][groupingProperty])
        },
        values: groupValues,
        valuesByAccountingOrganisationId: grouped,

        noCode: noCode(groupValues),
        differentCodeNames: analyseCodeNames(groupValues),
        differentTypes: analyseTypes(groupValues),
        neverUsed: neverUsed(groupValues),
        inAllEntities: inAllEntities(grouped, chartOfAccountResponse?.accountingOrganisations),
        missingInSomeEntities: missingInSomeEntities(grouped, chartOfAccountResponse?.accountingOrganisations)
      }
    },
    coa => formatGroupingText(coa[groupingProperty])
  )

  // Filter for highlights
  const highlights = menuOptions.highlights?.selectedOption()?.value

  const rows = Array.from(groupedMap.values())

  const filteredRows = rows.filter(row => {
    if (highlights === 'all') return true
    if (highlights === 'noCode') return row.noCode
    if (highlights === 'differentCodeNames') return row.differentCodeNames
    if (highlights === 'differentTypes') return row.differentTypes
    if (highlights === 'neverUsed') return row.neverUsed
    if (highlights === 'inAllEntities') return row.inAllEntities
    if (highlights === 'missingInSomeEntities') return row.missingInSomeEntities

    return true
  })
  return filteredRows
}

function formatGroupingText (value) {
  if (!value) return value

  // Match leading and trailing spaces and replace them with middle dots
  return value.replace(/^\s+|\s+$/g, match => '⸱'.repeat(match.length))
}

function noCode (groupValues) {
  let entitiesWithNoCode = 0
  groupValues.forEach(function (coa) {
    if (!coa.code) entitiesWithNoCode++
  })
  return entitiesWithNoCode > 0
}

function missingInSomeEntities (grouped, accountingOrganisations) {
  return grouped.size !== accountingOrganisations.length
}

function inAllEntities (grouped, accountingOrganisations) {
  return grouped.size === accountingOrganisations.length
}

function neverUsed (groupValues) {
  let entitiesNeverUsed = 0
  groupValues.forEach(function (coa) {
    if (+coa.number_ledgers === 0) entitiesNeverUsed++
  })
  return entitiesNeverUsed > 0
}

function analyseTypes (groupValues) {
  const uniqueValues = new Set()
  groupValues.forEach(function (coa) {
    uniqueValues.add([coa.statement, coa.xero_type].join(' <> '))
  })
  return uniqueValues.size > 1
}

function analyseCodeNames (groupValues) {
  const uniqueValues = new Set()
  groupValues.forEach(function (coa) {
    uniqueValues.add([coa.code, coa.name].join(' <> '))
  })
  return uniqueValues.size > 1
}

function chartOfAccountsToInclude (chartOfAccounts, menuOptions) {
  console.log('chartOfAccountsToInclude', menuOptions)
  const filterStatement = menuOptions.filterStatement.selectedOption()?.value
  const filterActive = menuOptions.filterActive.selectedOption()?.value

  function filterFunction (coa) {
    // Never show the zenflow accounts (that would be earnings, translation reserves...)
    if (coa.is_zenflow === true) return false

    // Statement Filter
    const statementAccepted = []
    if (['both', 'pnl'].includes(filterStatement)) statementAccepted.push('pnl')
    if (['both', 'bs'].includes(filterStatement)) statementAccepted.push('bs')
    const passStatementFilter = statementAccepted.includes(coa.statement)
    if (!passStatementFilter) return false

    if (filterActive === 'active' && coa.active !== true) return false
    if (filterActive === 'archived' && coa.active !== false) return false

    return true
  }

  return chartOfAccounts.filter(filterFunction)
}
