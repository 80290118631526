import dayjs from 'dayjs'

export function makeColumnDefs (accountData, currencyResponse) {
  console.log(currencyResponse)

  const columnDefs = []

  columnDefs.push({
    headerName: 'Entity',
    field: 'name'
  })

  columnDefs.push({
    headerName: 'Latest Done',
    valueGetter: function (cellParams) {
      const startedAt = cellParams.data.syncStats?.latestDone?.started_at
      return startedAt
    },
    valueFormatter: function (cellParams) {
      const cellDate = cellParams.value
      if (!cellDate) return null
      const cellDateDayjs = dayjs(cellDate)
      const showYear = cellDateDayjs.year() !== dayjs().year()
      const format = `D MMM ${showYear ? 'YYYY' : ''} @ HH:mm`
      return dayjs(cellDateDayjs).format(format)
    },
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Duration (sec)',
    valueGetter: function (cellParams) {
      // const startedAt = cellParams.data.syncStats?.latestDone?.started_at
      const duration = cellParams.data.syncStats?.latestDone?.sync_duration_seconds
      return duration ? parseFloat(duration) : null
    },
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Next Scheduled',
    valueGetter: function (cellParams) {
      const scheduleTime = cellParams.data.syncStats?.nextScheduled?.scheduled_time

      const isOngoing = cellParams.data.syncStats?.ongoing
      if (isOngoing) return 'Ongoing'

      return scheduleTime
    },
    cellRenderer: function (cellParams) {
      const cellDate = cellParams.value
      if (!cellDate) return null
      if (cellDate === 'Ongoing') return 'Ongoing'

      const cellDateDayjs = dayjs(cellDate)
      const showYear = cellDateDayjs.year() !== dayjs().year()
      const format = `D MMM ${showYear ? 'YYYY' : ''} @ HH:mm`

      // console.log('cellDateDayjs', cellDateDayjs, cellDateDayjs.format('DD MMM YYYY @ HH:mm:ss'), 'vs.', dayjs().format('DD MMM YYYY @ HH:mm:ss'), 'isBefore?', cellDateDayjs.isBefore(dayjs()))

      const tag = cellDateDayjs.isBefore(dayjs()) ? '<span class="tag is-light is-info">Starts soon</span> ' : ''
      return tag + dayjs(cellDateDayjs).format(format)
    },
    type: 'numericColumn',
    cellClass: ['is-flex', 'is-align-items-center'] // vertical align
  })

  columnDefs.push({
    headerName: 'Refresh',
    valueGetter: function (cellParams) {
      const isOngoing = cellParams.data.syncStats?.ongoing
      if (isOngoing) return false

      return true
    },
    cellRenderer: function (cellParams) {
      const isRefreshable = cellParams.value
      if (!isRefreshable) return null

      if (cellParams.data.isLoading) return '<button class="button is-small is-primary is-loading zen-vertical-align-baseline" disabled>...</button>'

      const scheduleTime = cellParams.data.syncStats?.nextScheduled?.scheduled_time
      const isScheduledSoon = dayjs(scheduleTime).isBefore(dayjs().add(2, 'minutes'))
      if (scheduleTime && isScheduledSoon) return '<button class="button is-small is-primary is-disabled zen-vertical-align-baseline" disabled>Already scheduled</button>'

      const doneTime = cellParams.data.syncStats?.latestDone?.started_at
      const isDoneRecently = dayjs(doneTime).isAfter(dayjs().subtract(2, 'minutes'))
      if (cellParams.data.syncStats?.latestDone && isDoneRecently) return '<button class="button is-small is-primary is-disabled zen-vertical-align-baseline" disabled>Just Done</button>'

      return `<button class="button is-small is-primary zen-vertical-align-baseline" data-action="refresh-asap" data-entity-id="${cellParams.data.id}">Refresh asap</button>`
    },
    cellClass: ['is-flex', 'is-align-items-center'] // vertical align
  })

  return columnDefs
}
