import { truncate } from 'lodash'

/**
 *
 * @param {Object} sourceData
 * @param {String} sourceData.document_number
 * @param {String} sourceData.reference
 * @param {String} sourceData.type_api
 * @returns
 */
export function makeSourceName (sourceData) {
  const referenceTrunc = 15

  const truncatedRef = truncate(sourceData.reference, {
    length: referenceTrunc
  })

  const sourceName = [sourceData.document_number, truncatedRef].join(' ').trim()

  if (!sourceName) return sourceData.type_api

  return sourceName
}
