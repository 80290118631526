import dayjs from 'dayjs'
import { formatNumber } from '../../../../../helpers/helpers/numbers.js'
import { fillString } from '../../../../../helpers/templating/string-template.js'
import { makeXeroLink } from '../../../../helpers/xero-links.js'
import { outputChartOfAccountText } from '../output-chart-of-account-text.js'
import { linkToSource } from '../link-to-source.js'
import { makeSourceName } from '../make-source-name.js'

export default function makeColumnDefs (context) {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Journal Date',
    valueGetter: function (cellParams) {
      const data = cellParams.data.journal_date
      if (!data) return null
      const date = dayjs(data)

      return date.format('YYYY-MM-DD')
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Statement',
    valueGetter: function (cellParams) {
      const statement = cellParams.data.chart_of_account_statement
      return statement
    },
    pinned: 'left'
  })

  // Chart of Account
  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      console.log('cellParams.data', cellParams.data)
      return outputChartOfAccountText(cellParams.data)
    },
    cellRenderer: function (cellParams) {
      const outputTemplate = ':accountText [<a href=":urlToXero" target="_blank">Xero</a>]'

      // TODO: manage when multiple entities
      const shortCode = getEntityShortcode(cellParams.context.accountData, context.showOrgId)

      const fromISOdate = dayjs(cellParams.data.journal_date).format('YYYY-MM-DD')
      const toISOdate = dayjs(cellParams.data.journal_date).format('YYYY-MM-DD')

      const urlToXero = makeXeroLink('ReportAccountTransactions', {
        shortCode,
        xeroAccountId: cellParams.data.chart_of_account_xero_id,
        fromISOdate,
        toISOdate
      })

      return fillString(outputTemplate, {
        accountText: cellParams.value,
        urlToXero
      })
    },
    width: 200,
    pinned: 'left'
  })

  // Amount
  columnDefs.push({
    headerName: 'Amount',
    field: 'amount',
    valueFormatter: function (cellParams) {
      return formatNumber(cellParams.value, '2')
    },
    type: 'numericColumn',
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Recorded Date',
    valueGetter: function (cellParams) {
      const data = cellParams.data.journal_created_at
      if (!data) return null
      const date = dayjs(data)

      return date.format('YYYY-MM-DD @ HH:mm:ss')
    }
    // width: 180
  })

  const trackingColDefs = includeTrackings(context)
  if (trackingColDefs) {
    columnDefs.push(...trackingColDefs)
  }

  // Contact Name
  columnDefs.push({
    headerName: 'Client/Vendor',
    valueGetter: function (cellParams) {
      const contactName = cellParams.data.contact_name
      // if (!contactName) return null

      return contactName
    },
    cellRenderer: function (cellParams) {
      const contactName = cellParams.data.contact_name
      if (!contactName) return null

      const shortCode = getEntityShortcode(cellParams.context.accountData, context.showOrgId)

      const contactLinkUrl = makeXeroLink('Contact', {
        shortCode,
        xeroContactId: cellParams.data.contact_xero_id
      })
      return fillString(':contactName [<a href=":contactLinkUrl" target="_blank">Xero</a>]', {
        contactName,
        contactLinkUrl
      })
    }
  })

  columnDefs.push({
    headerName: 'Journal Type',
    valueGetter: function (cellParams) {
      return fillString(':xeroSourceType', {
        xeroSourceType: (cellParams.data.journal_xero_source_type || '')
      })
    }
  })

  columnDefs.push({
    headerName: 'Source Type',
    valueGetter: function (cellParams) {
      if (!cellParams.data.source_type_api) return null

      const typeApi = cellParams.data.source_type_api
      const xeroSourceType = cellParams.data.source_xero_source_type
      const template = `${typeApi} ${xeroSourceType ? ' > ' + xeroSourceType : ''}`
      return template
    }
  })

  // Source: eg. Sales Invoice: XXX
  columnDefs.push({
    headerName: 'Source',
    valueGetter: function (cellParams) {
      if (!cellParams.data.source_xero_id) return null

      return makeSourceName(cellParams.data)
    },
    cellRenderer: function (cellParams) {
      if (!cellParams.data.source_xero_id) return null

      const shortCode = getEntityShortcode(cellParams.context.accountData, context.showOrgId)

      const sourceData = {
        xero_id: cellParams.data.source_xero_id,
        type_api: cellParams.data.source_type_api,
        xero_source_type: cellParams.data.source_xero_source_type,
        document_number: cellParams.data.source_document_number,
        reference: cellParams.data.source_reference
      }
      const mainSourceText = linkToSource(cellParams.data, sourceData, shortCode)

      // Add related source: ie payments on invoices

      if (!cellParams.data.related_source_xero_id) return mainSourceText

      const relatedSourceData = {
        xero_id: cellParams.data.related_source_xero_id,
        type_api: cellParams.data.related_source_type_api,
        xero_source_type: cellParams.data.related_source_xero_source_type,
        document_number: cellParams.data.related_source_document_number,
        reference: cellParams.data.related_source_reference
      }
      const relatedSourceText = linkToSource(cellParams.data, relatedSourceData, shortCode)
      return fillString(':mainSourceText for :relatedSourceText', {
        mainSourceText,
        relatedSourceText
      })
    },
    minWidth: 250
  })

  // Status
  // Eg. if transaction was reversed

  // How about multi currency?

  // Amount source currency (when multi currency); based on source?

  // Reference
  columnDefs.push({
    field: 'journal_reference'
  })

  // Description
  columnDefs.push({
    field: 'journal_line_description'
  })

  columnDefs.push({
    field: 'journal_number',
    type: 'numericColumn'
  })

  // TODO: Is interco? related company?

  // TODO: Items?

  return columnDefs
}

/**
 * TODO: manage multiple entities
 * @param {*} context
 * @returns
 */
export function includeTrackings (context) {
  const colDefs = []

  // console.log('includeTrackings', context)
  context.data.trackings?.forEach(function (oneCategory) {
    const colDef = {
      headerName: oneCategory.name,
      context: {
        tracking: oneCategory
      },
      valueGetter: function (cellParams) {
        // console.log('tracking cellParams', cellParams)

        const columnTrackingCategoryId = cellParams.colDef.context?.tracking?.id

        const entryTrackingForCategory = cellParams.data.trackings?.find(tracking => tracking.tracking_category_id === columnTrackingCategoryId)
        // console.log('entryTrackingForCategory', entryTrackingForCategory)

        if (!entryTrackingForCategory) return

        const trackingOption = cellParams.colDef.context?.tracking?.TrackingOptions?.find(one => one.id === entryTrackingForCategory.tracking_option_id)

        return trackingOption?.name
      }
    }

    colDefs.push(colDef)
  })
  // console.log('colDefs', colDefs)
  return colDefs
}

function getEntityShortcode (accountData, orgId) {
  const targetAccountOrg = accountData._accountOrganisations.find(function (one) {
    return one._xeroOrganisation?._id === orgId
  })
  const targetEntity = targetAccountOrg?._xeroOrganisation
  const shortCode = targetEntity.details?.shortCode

  return shortCode
}
