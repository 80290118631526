import { ascending, index } from 'd3'

export function makeRows (context) {
  console.log('makeRows', context)

  // Identify target chart of accounts
  const chartOfAccountsData = context?.data?.convertedValuesResults?.chartOfAccounts
  const mapOfChartOfAccounts = index(chartOfAccountsData, d => d.id)

  const valuesArray = context?.data?.convertedValuesResults?.convertedValues
  // const accountTypesMap = index(context?.data?.accountTypes, d => d.accountType)
  // console.log('accountTypesMap', accountTypesMap)

  const rows = []
  valuesArray.forEach(function (value) {
    const chartOfAccount = mapOfChartOfAccounts.get(value.accounting_chart_of_account_id)
    const row = {
      chartOfAccount,
      // accountType: accountTypesMap.get(chartOfAccount?.xero_type),
      value
    }
    rows.push(row)
  })

  // Sort
  rows.sort(function (r1, r2) {
    return ascending(r1.value?.accounting_chart_of_account_id, r2.value?.accounting_chart_of_account_id)
  })

  return rows
}
