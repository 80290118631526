import { byAllAccounts } from './accounts-list/by-all-accounts.js'
import { makeColumnDefs } from './accounts-list/make-column-defs.js'
import { byLayout } from './statement-layout/by-layout.js'
import { makeLayoutColumnDefs } from './statement-layout/make-layout-column-defs.js'
import { computeConsolidation } from './compute/consolidation-data.js'
import { indexValuesDB } from './index-values-db.js'

// const GRID_METHOD = 'byAllAccounts'
export const GRID_METHOD = 'byLayout'

export function makeGridData (context) {
  console.log('makeGridData', context)

  computeConsolidation(context)
  indexValuesDB()

  if (GRID_METHOD === 'byAllAccounts') {
    return {
      rows: byAllAccounts(context),
      columns: makeColumnDefs(context)
    }
  } else {
    console.time('makeGridData')
    const columns = makeLayoutColumnDefs(context)
    const rows = byLayout(context, columns)
    // console.log('columns', columns)
    console.timeEnd('makeGridData')

    return {
      rows,
      columns
    }
  }
}
