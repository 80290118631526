// Router using https://visionmedia.github.io/page.js/
// Last Updated 2020: React, VueJS and Angular probably took over.
// Flo still likes the simplicity of this front end router
//
import * as configVar from './config/config.js'

import * as pageUserAuth from './pages/user/auth/index.js'
import * as pageUserAccounts from './pages/user/accounts/index.js'
import * as pageAccount from './pages/account/index.js'

import * as pageHome from './pages/home/index.js'
import * as pageDemoMongo from './pages/demo-mongo/index.js'

// Settings of an account
import * as pageXero from './pages/xero/index.js'
import * as pageChartsOfAccounts from './pages/charts-of-accounts/index.js'
import * as pageCurrencies from './pages/currencies/index.js'
import * as pageSubscription from './pages/subscription/index.js'

// Features for an account
import * as pageFinancials from './pages/financials/index.js'

import * as mUser from './modules/user/user.js'

import * as pDemoLayout from './pages/demos/layout/index.js'
import { setOnChangeStatementType } from './modules/option-selector/data.js'
import { showSchedule } from './pages/schedules/schedules.js'
import { showPageEliminationSuggestions } from './pages/multi-entities-info/eliminations-suggestions/index.js'
import { showTrialBalances } from './pages/financials/trial-balances/trial-balances-index.js'
import { loadPageStarter } from './config/app-starter.js'
import { showCohortPage } from './pages/cohorts/index.js'
import { showDemoComponent } from './pages/demos/components/index.js'
import { showReceivables } from './pages/receivables/receivables.js'
import { fillString } from '../helpers/templating/string-template.js'
import { showPageTrialBalances } from './pages/financials/trial-balances-v2/trial-balances-index.js'
import { showSchedulesPage } from './pages/financials/schedules/show-schedules-page.js'
import { showPageEntities } from './pages/entities/index.js'
import { showPageStatements } from './pages/v202310/statements/show-page-statements.js'
import { showPageManualConsolidation } from './pages/manual-consolidation/view/show-page-manual-consolidation.js'
import { showPageUpload } from './pages/manual-consolidation/upload/show-page-upload.js'
import { showPageItemsGrouping } from './pages/manual-consolidation/group/show-page-items-grouping.js'
import { showCommercePage } from './pages/commerce/platforms/index.js'
import { showPageMonthlyValues } from './pages/v202310/monthly-values/show-page-monthly-values.js'
import { showPageCrossContacts } from './pages/multi-entities-info/cross-contacts/index.js'
import { showPageAllContacts } from './pages/multi-entities-info/all-contacts/index.js'
import { showPageTrackings } from './pages/trackings/index.js'
import { showPageCashTransactions } from './pages/v202310/cash-transactions/show-page-cash-transactions.js'
import { showPageCashJournalTransactions } from './pages/v202310/cash-journals-transactions/show-page-cash-journal-transactions.js'
import { showPageEliminationsJournalTransactions } from './pages/v202310/eliminations-journals-transactions/show-page-eliminations-journal-transactions.js'
import page from 'page'
import { showPageProvisionsJournalTransactions } from './pages/v202310/provisions-journals-transactions/show-page-provisions-journal-transactions.js'
import { showPageLinkExtensionChrome } from './pages/extension-chrome/show-page-link-extension-chrome.js'
import { showPageJournals } from './pages/accounting/journals/index.js'
import { showPageJournalsAggregations } from './pages/accounting/aggregations/index.js'
import { showPageJournalsStatement } from './pages/accounting/statements/index.js'
import { showPageJournalsDebug } from './pages/accounting/debug/index.js'
import { showPageJournalsMonthlyValues } from './pages/accounting/monthly-pnl-values/index.js'
import { showPageNewAccountsUsed } from './pages/accounting/new-accounts-used/index.js'
import { showPageAnalysisByContact } from './pages/accounting/by-contacts/index.js'
import { showPageGroupTransactions } from './pages/multi-entities-info/group-transactions/index.js'
import { showPageJournalsMonthlyValuesPg } from './pages/accounting/monthly-pnl-values-pg/index.js'
import { showPageMonthlyValuesDataReview } from './pages/accounting/monthly-values-data-review/index.js'
import { showPageJournalsMonthlyValuesPgLedger } from './pages/accounting/monthly-pnl-values-pg-ledger/index.js'
import { showPageFinancialsPg } from './pages/accounting/pg-financials/index.js'
import { showPageWhatHasBeenDonePg } from './pages/accounting/journals-what-been-done-pg/index.js'
import { showPageMonthlyAnalysisPG } from './pages/accounting/monthly-analysis-pg/index.js'
import { showPageFinancialsWithConversionPg } from './pages/accounting/pg-financials-conversion/index.js'
import { showPageFinancialsWithConversionPgDetails } from './pages/accounting/pg-financials-conversion-details/index.js'
import { showPageChartOfAccountsPG } from './pages/pg-charts-of-accounts/index.js'
import { showPageCurrenciesPG } from './pages/pg-currencies/index.js'
import { showPageEntitiesPG } from './pages/pg-entities/index.js'
import { showPageDataSyncsPG } from './pages/pg-syncs/index.js'
import { showPageTrackingsPG } from './pages/pg-trackings/index.js'
import { showPageCopyPgFromMongo } from './pages/pg-copy-pg-from-mongo/index.js'

// Set the base route urls
page.base(configVar.appRoutes)

//
page.redirect('/', '/home')
page('/home', async function (context) {
  await loadPageStarter()

  if (!mUser.isUserLoggedIn()) {
    page('/auth')
  } else {
    // page('/user/accounts')
    pageHome.show()
  }
})

//
page('/demo-mongo', async function (context) {
  await loadPageStarter()

  pageDemoMongo.show()
})

page('/xero-connected', function () {
  const accountId = localStorage.getItem('accountId')

  const redirectUrl = ['/app/a', accountId, 'xero'].join('/')
  console.log('go to', redirectUrl)
  page(redirectUrl)
})

/**
 * /app/invite/:inviteId
 */
page('/invite/:inviteId', async function (context) {
  const { inviteId } = context.params
  await loadPageStarter()

  pageAccount.showInviteAcceptPage({ inviteId })
})

/**
 * /app/a/:accountId
 */
page('/a/:accountId', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  pageAccount.show({ accountId })
})

//
page('/a/:accountId/xero', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  pageXero.show(accountId)
})

//
page('/a/:accountId/entities', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageEntities()
})

page('/a/:accountId/trackings', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageTrackings()
})

//
page('/a/:accountId/charts-of-accounts', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  pageChartsOfAccounts.show(accountId)
})

/**
 * Feb 2025: PG page
 */
page('/a/:mongoAccountId/admin-copy-pg-from-mongo', async function (context) {
  const { mongoAccountId } = context.params
  await loadPageStarter({ accountId: mongoAccountId })

  showPageCopyPgFromMongo(mongoAccountId)
})

/**
 * Feb 2025: PG page
 */
page('/a/:mongoAccountId/manage-charts-of-accounts', async function (context) {
  const { mongoAccountId } = context.params
  await loadPageStarter({ accountId: mongoAccountId })

  showPageChartOfAccountsPG(mongoAccountId)
})

/**
 * Feb 2025: PG page
 */
page('/a/:mongoAccountId/manage-currencies', async function (context) {
  const { mongoAccountId } = context.params
  await loadPageStarter({ accountId: mongoAccountId })

  showPageCurrenciesPG(mongoAccountId)
})

/**
 * Feb 2025: PG page
 */
page('/a/:mongoAccountId/manage-entities', async function (context) {
  const { mongoAccountId } = context.params
  await loadPageStarter({ accountId: mongoAccountId })

  showPageEntitiesPG(mongoAccountId)
})

/**
 * Feb 2025: PG page
 */
page('/a/:mongoAccountId/manage-data-syncs', async function (context) {
  const { mongoAccountId } = context.params
  await loadPageStarter({ accountId: mongoAccountId })

  showPageDataSyncsPG(mongoAccountId)
})

/**
 * Feb 2025: PG page
 */
page('/a/:mongoAccountId/manage-trackings', async function (context) {
  const { mongoAccountId } = context.params
  await loadPageStarter({ accountId: mongoAccountId })

  showPageTrackingsPG(mongoAccountId)
})

//
page('/a/:accountId/eliminations-suggestions', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageEliminationSuggestions(accountId)
})

//
page('/a/:accountId/group-transactions', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageGroupTransactions(accountId)
})

/**
 *
 */
page('/a/:accountId/cross-contacts', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageCrossContacts(accountId)
})

/**
 *
 */
page('/a/:accountId/all-contacts', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageAllContacts(accountId)
})

//
page('/a/:accountId/currencies', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  pageCurrencies.show(accountId)
})

//
page('/a/:accountId/subscription', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  pageSubscription.show(accountId)
})

/**
 * October 2023
 */
page('/a/:accountId/statements', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageStatements(accountId)
})

page('/a/:accountId/monthly-values', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageMonthlyValues(accountId)
})

/** Feb 2025 */
page('/a/:accountId/what-has-been-done', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId
  })
  console.log('showPageWhatHasBeenDonePg...')

  showPageWhatHasBeenDonePg(accountId)
})

/**
 * Sep 2024: using Journals collection
 */
page('/a/:accountId/journals', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageJournals(accountId)
})

page('/a/:accountId/journals-aggregations', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageJournalsAggregations(accountId)
})

page('/a/:accountId/journals-statement/:statementType', async function (context) {
  const { accountId, statementType } = context.params
  await loadPageStarter({
    accountId
    // loadChartOfAccounts: true
  })

  showPageJournalsStatement(statementType)
})

// Feb 12th 2025: new route for financials pg ledger, with conversion
page('/financials-multi-currency/a/:accountId/:statementType', async function (context) {
  const { accountId, statementType } = context.params
  await loadPageStarter({
    accountId
    // loadChartOfAccounts: true
  })

  showPageFinancialsWithConversionPg({ statementType })
})

page('/financials-multi-currency-details/a/:accountId', async function (context) {
  const { accountId, statementType } = context.params
  await loadPageStarter({
    accountId
    // loadChartOfAccounts: true
  })

  showPageFinancialsWithConversionPgDetails({ statementType })
})

// Feb 4th 2025: new route for financials pg ledger
page('/financials/a/:accountId/:statementType', async function (context) {
  const { accountId, statementType } = context.params
  await loadPageStarter({
    accountId
    // loadChartOfAccounts: true
  })

  showPageFinancialsPg({ statementType })
})

// Feb 4th 2025: Direct to an entity
page('/financials/o/:orgId/:statementType', async function (context) {
  const { orgId, statementType } = context.params
  await loadPageStarter({
    orgId
  })

  showPageFinancialsPg({ orgId, statementType })
})

page('/a/:accountId/journals-monthly-values/', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageJournalsMonthlyValues()
})

page('/a/:accountId/journals-monthly-values-pg/', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageJournalsMonthlyValuesPg()
})

page('/a/:accountId/journals-monthly-values-pg-ledger/', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageJournalsMonthlyValuesPgLedger()
})

page('/a/:accountId/monthly-values-data-review', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId
  })

  showPageMonthlyValuesDataReview(accountId)
})

page('/a/:accountId/journals-debug', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageJournalsDebug(accountId)
})

page('/a/:accountId/new-accounts-used', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageNewAccountsUsed(accountId)
})

page('/a/:accountId/monthly-analysis/:analysisType', async function (context) {
  const { accountId, analysisType } = context.params
  await loadPageStarter({
    accountId,
    // loadChartOfAccounts: true,
    loadCurrencies: true // Needed for conversion (To be updated)
  })

  showPageMonthlyAnalysisPG(analysisType)
})

page('/a/:accountId/analysis/:analysisType', async function (context) {
  const { accountId, analysisType } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true,
    loadCurrencies: true
  })

  showPageAnalysisByContact(analysisType)
})

/**
 * ***********
 */

page('/a/:accountId/cash-transactions', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageCashTransactions(accountId)
})

page('/a/:accountId/cash-journal-transactions', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageCashJournalTransactions(accountId)
})

page('/a/:accountId/eliminations-journal-transactions', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageEliminationsJournalTransactions(accountId)
})

page('/a/:accountId/provisions-journal-transactions', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageProvisionsJournalTransactions(accountId)
})

/**
 * End of October 2023
 */

/**
 * Aug 2023: Contact details
 */
page('/a/:accountId/schedules', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showSchedulesPage({
    accountId
  })
})

/**
 * Aug 2023: trial balance page v2: based on ledger
 */
page('/a/:accountId/trial-balances', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageTrialBalances({
    accountId
  })
})

/**
 * Nov 2023: CSVs conso
 */
page('/a/:accountId/manual-statements', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageManualConsolidation({
    accountId
  })
})

page('/a/:accountId/manual-upload', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageUpload({
    accountId
  })
})

page('/a/:accountId/manual-grouping', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({ accountId })

  showPageItemsGrouping({
    accountId
  })
})

//
page('/a/:accountId/trial-balances/:orgId', async function (context) {
  const { accountId, orgId } = context.params
  await loadPageStarter({ accountId, orgId })

  console.log('trial-balances orgId', orgId)

  const statementType = 'trial-balances'

  showTrialBalances({
    accountId,
    orgId,
    statementType
  })
})

// Receivables
page('/a/:accountId/receivables/:orgId', async function (context) {
  const { accountId, orgId } = context.params
  await loadPageStarter({ accountId, orgId })
  // console.log('/a/:accountId/receivables/:orgId')
  // console.log('orgId', orgId)

  showReceivables({
    accountId, orgId
  })
})

// Redirect v1 of financial statements
page('/a/:accountId/financials/:orgId', function (context) {
  const { accountId, orgId } = context.params
  let redirectUrl = '/a/:accountId/pnl/:orgId'
  redirectUrl = fillString(redirectUrl, { accountId, orgId })
  page.redirect(redirectUrl)
})

/**
 * /app/a/:accountId/pnl/all
 * /app/a/:accountId/bs/all
 * /app/a/:accountId/trial-balances/all
 */
page('/a/:accountId/:statementType/:orgId', async function (context) {
  const { accountId, orgId, statementType } = context.params
  await loadPageStarter({ accountId, orgId })

  if (!['pnl', 'bs', 'forecast'].includes(statementType)) {
    console.error('invalid statement type', context.params)
  }

  setOnChangeStatementType(statementType)

  console.log('orgId', orgId)
  pageFinancials.show({
    accountId,
    orgId,
    statementType
  })
})

// Schedules
page('/a/:accountId/schedule/:orgId/:chartAccountId', async function (context) {
  const { accountId, orgId, chartAccountId } = context.params
  await loadPageStarter({ accountId, orgId })
  console.log('/a/:accountId/schedule/:orgId/:chartAccountId')
  console.log('orgId', orgId)

  showSchedule({
    accountId, orgId, chartAccountId
  })
})

page('/a/:accountId/link-chrome-extension', async function (context) {
  const { accountId } = context.params
  await loadPageStarter({
    accountId,
    loadChartOfAccounts: true
  })

  showPageLinkExtensionChrome(accountId)
})

//
page('/user/accounts', async function (context) {
  await loadPageStarter()

  pageUserAccounts.show()
})

//
page('/auth', async function (context) {
  await loadPageStarter()

  pageUserAuth.show()
})

page('/demo', function (context) {
  console.log('demo')
  showDemoComponent()
})

page('/demo/layout', function (context) {
  pDemoLayout.show()
})

page('/cohorts', function (context) {
  showCohortPage()
})

page('/commerce', function (context) {
  showCommercePage()
})

//
page('*', async function (context) {
  console.log('other routes', context)
  await loadPageStarter()
})

// Init the router
page()
