import { callApi } from '../helpers/fetch.js'

//
export async function loadEntities (mongoAccountId) {
  const apiUrl = '/api/finlogic/data/:accountId/pg-accounting-orgs'
  const data = await callApi(apiUrl, {
    accountId: mongoAccountId
  })
  // console.log('api data', data)

  return data
}
