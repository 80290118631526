
// App config
import * as configVar from '../../../config/config.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'
import { appAccount } from '../../../modules/user/account.js'
import * as mHtmlParser from '../../../modules/user/html-parser.js'

// Page Module
import htmlTemplateIndex from './index.html'
import { updateView } from './update-view.js'
/**
 *
 * @returns
 */
export async function showPageGroupTransactions () {
  console.log('showPageGroupTransactions()')

  if (mUser.enforceRestrictedPage({ allowPublic: true })) return

  const accountData = appAccount.accountData()

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Group Transactions:', accountData.name].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  let htmlPageLayout = htmlTemplateIndex
  htmlPageLayout = mHtmlParser.applyRestrictions(htmlPageLayout, { accountData })

  // Inject the page in the app container
  DOMelement.innerHTML = htmlPageLayout

  document.getElementById('app-content').classList.add('position-absolute')
  document.getElementById('app-content').classList.add('top-to-bottom')

  await updateView()
}
