import { roundNumber } from '../../../../helpers/numbers/round-number.js'

export function valueMaker (groupData, targetMonth, context) {
  // console.log(targetMonth, 'groupData', groupData)

  const cellObject = groupData.values?.find(function (value) {
    return value.month === targetMonth
  })
  if (!cellObject) return null

  let rawValue = getRawValue(context, cellObject)

  rawValue = roundNumber(rawValue, 4)

  if (context.statementType === 'pnl') {
    // Values are debit positive; we turn it otherwise for PnL
    return -1 * (rawValue || 0)
  }

  return rawValue
}

function getRawValue (context, cellObject) {
  if (context.valueBalance === 'balance') {
    return +cellObject?.balance
  } else {
    return +cellObject?.amount
  }
}
