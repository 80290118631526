export function makeTargetChartOfAccountIds (cellParams) {
  let targetChartOfAccountIds = []
  if (cellParams.data.children) {
    targetChartOfAccountIds = cellParams.data.children.flatMap(function (d) {
      return d.context?.chartOfAccounts?.map(function (d) {
        return d.id
      })
    })
  } else {
    targetChartOfAccountIds = cellParams.data.context?.chartOfAccounts?.map(d => d.id)
  }
  return targetChartOfAccountIds
}
