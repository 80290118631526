import { generateButtons } from '../../helpers/menus/index.js'
import { updateView } from './update-view.js'

export const menuOptions = {}

export function showMenu () {
  menuOptions.optionGrouping = generateButtons({
    containerId: 'optionGrouping',
    groupLabel: 'Accounts grouped by',
    buttonOptions: [
      {
        label: 'Code',
        value: 'code'
      },
      {
        label: 'Name',
        value: 'name'
      }
    ],
    selected: 'name',
    onChange: function () {
      updateView()
    }
  })

  menuOptions.outputShow = generateButtons({
    containerId: 'outputShow',
    groupLabel: 'Show',
    buttonOptions: [
      {
        label: 'Code',
        value: 'code'
      },
      {
        label: 'Name',
        value: 'name'
      },
      {
        label: 'Type',
        value: 'type'
      },
      {
        label: '90 days Use',
        value: 'usageRecent'
      },
      {
        label: 'Total Use',
        value: 'usageTotal'
      }
    ],
    selected: 'usageRecent',
    onChange: function () {
      updateView()
    }
  })

  menuOptions.filterStatement = generateButtons({
    containerId: 'filterStatement',
    groupLabel: 'Financial Statement',
    buttonOptions: [
      {
        label: 'Balance Sheet',
        value: 'bs'
      },
      {
        label: 'Profit & Loss',
        value: 'pnl'
      },
      {
        label: 'Both',
        value: 'both'
      }
    ],
    selected: 'pnl',
    onChange: function () {
      updateView()
    }
  })

  menuOptions.filterActive = generateButtons({
    containerId: 'filterActive',
    groupLabel: 'Active/Archived?',
    buttonOptions: [
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Archived',
        value: 'archived'
      },
      {
        label: 'Both',
        value: 'both'
      }
    ],
    selected: 'active',
    onChange: function () {
      updateView()
    }
  })

  menuOptions.highlights = generateButtons({
    containerId: 'highlights',
    groupLabel: 'Highlights',
    buttonOptions: [
      {
        label: 'All',
        value: 'all'
      },
      {
        label: 'No Code',
        value: 'noCode'
      },
      {
        label: 'Different Values (Code <> Name)',
        value: 'differentCodeNames'
      },
      {
        label: 'Different Types',
        value: 'differentTypes'
      },
      {
        label: 'Never Used in some entities',
        value: 'neverUsed'
      },
      {
        label: 'In all entities',
        value: 'inAllEntities'
      },
      {
        label: 'Missing in some entities',
        value: 'missingInSomeEntities'
      }
    ],
    selected: 'all',
    onChange: function () {
      updateView()
    }
  })
}
