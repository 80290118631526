import { formatNumber } from '../../../helpers/helpers/numbers.js'
import { associateCurrency } from './associate-currency.js'

export function makeColumnDefs (accountData, currencyResponse) {
  console.log(currencyResponse)

  const columnDefs = []

  columnDefs.push({
    field: 'code',
    width: 110
  })

  columnDefs.push({
    field: 'name'
  })

  columnDefs.push({
    headerName: 'Default?',
    field: 'is_default',
    valueGetter: function (cellParams) {
      return cellParams.data.AppAccountCurrencies?.[0]?.is_default
    },
    cellRenderer: function (cellParams) {
      return makeCellRenderer(cellParams)
    },
    onCellClicked: async function (cellParams) {
      console.log('onCellClicked Default', cellParams)

      await associateCurrency(accountData._id, cellParams, { isDefault: true })
    },
    filterParams: {
      textFormatter: function (value) {
        return value === true ? 'Yes' : 'No'
      }
    },
    cellClass: ['is-flex', 'is-align-items-center'] // vertical align
  })

  columnDefs.push({
    headerName: 'Use?',
    valueGetter: function (cellParams) {
      return cellParams.data.AppAccountCurrencies?.length > 0
    },
    cellRenderer: function (cellParams) {
      return makeCellRenderer(cellParams)
    },
    onCellClicked: async function (cellParams) {
      console.log('onCellClicked Associate', cellParams)
      await associateCurrency(accountData._id, cellParams)
    },
    filterParams: {
      textFormatter: function (value) {
        return value === true ? 'Yes' : 'No'
      }
    },
    cellClass: ['is-flex', 'is-align-items-center'] // vertical align
  })

  columnDefs.push({
    headerName: 'Latest Rate for US$1',
    valueGetter: function (cellParams) {
      return formatNumber(cellParams.data.currency_to?.[0].rate, 6)
    },
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'to US$',
    valueGetter: function (cellParams) {
      return formatNumber(1 / cellParams.data.currency_to?.[0].rate, 6)
    },
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Rate Date',
    valueGetter: function (cellParams) {
      return cellParams.data.currency_to?.[0].date
    }
  })

  columnDefs.push({
    headerName: '# Entities',
    valueGetter: function (cellParams) {
      return cellParams.data.accounting_organisations?.length
    },
    type: 'numericColumn'
  })

  const rowHeightDefault = 42

  columnDefs.push({
    headerName: 'Entities',
    valueGetter: function (cellParams) {
      return cellParams.data.accounting_organisations?.map(organisation => organisation.name).join(', ')
    },
    cellRenderer: function (cellParams) {
      const listOfEntityNames = cellParams.data.accounting_organisations?.map(organisation => organisation.name).join('<br>')

      const currentRowHeight = cellParams.node.rowHeight
      let multiRowHighlight = ''
      if (cellParams.data.accounting_organisations?.length > 1 && currentRowHeight === rowHeightDefault) {
        multiRowHighlight = cellParams.data.accounting_organisations?.length - 1 + ' & '
      }
      return multiRowHighlight + listOfEntityNames
    },
    onCellClicked: function (cellParams) {
      console.log('onCellClicked', cellParams)

      // const currentRowHeight = cellParams.node.getRowHeight()
      const currentRowHeight = cellParams.node.rowHeight

      if (currentRowHeight === rowHeightDefault) {
        cellParams.node.setRowHeight(rowHeightDefault * cellParams.data.accounting_organisations?.length)
      } else {
        cellParams.node.setRowHeight(rowHeightDefault)
      }
      cellParams.api.onRowHeightChanged()
      cellParams.api.refreshCells({
        rowNodes: [cellParams.node],
        force: true
      })
    },
    context: {
      component: 'text-cell'
    },
    cellStyle: {
      'white-space': 'normal'
    }
  })

  return columnDefs
}

function makeCellRenderer (cellParams) {
  const isLoading = cellParams.data.isLoading
  const buttonClass = cellParams.value === true ? 'is-success' : 'is-info is-info is-light'
  const buttonText = cellParams.value === true ? 'Yes' : 'No'

  const html = `<p class="buttons"><button class="button is-small ${buttonClass} ${isLoading ? 'is-loading' : ''}">${buttonText}</button></p>`

  return html
}
