import { appAccount } from '../../../modules/user/account.js'
import { createGrid } from 'ag-grid-community'
import { hideElement, showElement } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { makeColumnDefs } from './make-column-defs.js'
import { makeRows } from './make-rows.js'
import { loadData } from './load-data.js'

const context = {
  data: {}
}

export let gridApi

/**
 *
 */
export async function updateView () {
  console.log('updateView', optionSelectorData)
  showElement('statementLoadingMessage')
  hideElement('statementContainer')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.viewDecimals = optionSelectorData.decimals || 0
  context.filterDates = optionSelectorData.filterDates
  context.accountData = appAccount.accountData()
  // context.groupings = ['accounts', 'tracking1', 'tracking2']

  // Load data
  await loadData(context)
  console.log('context', context)

  // Include Top Pinned row with total of amounts

  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)

  const indexedChartOfAccounts = context.accountData?.chartOfAccounts?.indexed
  const gridRowsData = makeRows(context.data, indexedChartOfAccounts)

  if (gridApi) {
    gridApi.setGridOption('rowData', gridRowsData)
  } else {
    const gridOptions = {
      rowData: gridRowsData,
      columnDefs: makeColumnDefs(context),
      defaultColDef: {
        filter: true,
        floatingFilter: true,
        onCellClicked: function (cellParams) {
          console.log(cellParams)
        }
      },
      autoSizeStrategy: {
        type: 'fitCellContents'
      },
      context
    }
    gridApi = createGrid(gridContainerElement, gridOptions)
  }

  console.log('gridApi', gridApi)

  hideElement('statementLoadingMessage')
  showElement('statementContainer')
}
