import { callApi } from '../../../../helpers/fetch.js'

export async function syncManagementListeners (clickEvent, orgId) {
  console.log('syncManagementListeners', clickEvent)
  const targetElement = clickEvent.target.closest('.button')
  if (!targetElement) return

  const targetAction = targetElement.getAttribute('data-action')
  const targetOrgId = orgId // targetElement.getAttribute('data-orgId')

  console.log('targetElement', targetElement, targetAction, targetOrgId)

  await actions[targetAction](targetOrgId)
}

const actions = {
  scheduleSyncPostgres: async function (orgId) {
    await callApi('/api/sync-pg/schedule/:orgId', {
      orgId
    }, { method: 'POST' })
  },
  generateLedger: async function (orgId) {
    await callApi('/api/sync-pg/generate-ledger/:orgId', {
      orgId
    }, { method: 'POST' })
  },

  deletePgData: async function (orgId) {
    await callApi('/api/data-review/delete-pg-data/:orgId/all', {
      orgId
    }, { method: 'DELETE' })
  },
  deletePgDataJournals: async function (orgId) {
    await callApi('/api/data-review/delete-pg-data/:orgId/journals', {
      orgId
    }, { method: 'DELETE' })
  },
  deletePgDataLedger: async function (orgId) {
    await callApi('/api/data-review/delete-pg-data/:orgId/ledger', {
      orgId
    }, { method: 'DELETE' })
  },
  deletePgDataSources: async function (orgId) {
    await callApi('/api/data-review/delete-pg-data/:orgId/sources', {
      orgId
    }, { method: 'DELETE' })
  },
  deletePgDataTrialBalances: async function (orgId) {
    await callApi('/api/data-review/delete-pg-data/:orgId/trialbalances', {
      orgId
    }, { method: 'DELETE' })
  }

}
