import { ascending, group } from 'd3'

export default function makeAccountsRows (context) {
  console.log('context', context)
  const mappedEntries = group(context.data.journalsData,
    function (journalEntry) {
      return journalEntry.accounting_chart_of_account_id
    }
  )

  const rows = Array.from(mappedEntries, function (mapValues) {
    const entries = mapValues[1]

    const chartOfAccount = {
      id: entries[0].accounting_chart_of_account_id,
      chart_of_account_code: entries[0].chart_of_account_code,
      chart_of_account_name: entries[0].chart_of_account_name,
      chart_of_account_statement: entries[0].chart_of_account_statement,
      xero_id: entries[0].chart_of_account_xero_id
    }

    return {
      chartOfAccount,
      values: entries
    }
  })

  rows.sort(function (r1, r2) {
    function sortingKey (row) {
      return [row.chartOfAccount?.statement, row.chartOfAccount?.code, row.chartOfAccount?.name].join('')
    }
    const v1 = sortingKey(r1)
    const v2 = sortingKey(r2)
    return ascending(v1, v2)
  })

  console.log('rows', rows)

  return rows
}
