import { fillString } from '../../../../helpers/templating/string-template.js'
import { getOrgId } from './get-org-id.js'

import htmlTemplateOneOrgButton from './options-organisations-button.html'

export function makeEntitiesChoices (containerElementId, organisations) {
  const buttonsContainerElement = document.getElementById(containerElementId)
  if (!buttonsContainerElement) return

  organisations.forEach(function (oneOrganisation) {
    const htmlButton = makeButtonOrg(oneOrganisation)

    buttonsContainerElement.insertAdjacentHTML('beforeend', htmlButton)
  })
}

function makeButtonOrg (organisation) {
  if (organisation.type === 'break') {
    const breakElementHtml = '<div class="cell"> </div>'
    return breakElementHtml
  }

  const orgId = getOrgId(organisation)

  // MongoDB or PG version
  const entityCurrencyCode = organisation.details?.baseCurrency || organisation.base_currency

  const currencyText = `${entityCurrencyCode ? '(' + entityCurrencyCode + ')' : ''}`

  const htmlButton = fillString(htmlTemplateOneOrgButton, {
    orgId,
    orgName: organisation.name,
    baseCurrency: currencyText
  })

  return htmlButton
}
