import { callApi } from '../../../helpers/fetch.js'

export async function loadDataStatements (context) {
  console.log('loadDataStatements', context)
  const orgId = context.showOrgId
  if (!orgId) return

  const url = '/api/finlogic/data/:orgId/pg-ledgers-values/:monthFrom/:monthTo'

  const loadedData = await callApi(url, {
    orgId,
    monthFrom: context.dates?.min,
    monthTo: context.dates?.max
  })

  return loadedData
}
