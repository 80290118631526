import alasql from 'alasql'
import { alaSQLtableName } from '../alasql.js'

export function computeAlaSQLFormula (cellParams) {
  const results = []

  if (!Array.isArray(cellParams.data.formulaAlaSQL)) {
    cellParams.data.formulaAlaSQL = [cellParams.data.formulaAlaSQL]
  }

  cellParams.data.formulaAlaSQL?.forEach(function (formula) {
    const result = executeAlaSQL(cellParams, formula)
    results.push(result)
  })

  if (cellParams.data.formulaAlaSQLOperator === 'divide') {
    return results[0] / results[1]
  }

  return results.reduce((acc, result) => acc + result, 0)
}

function executeAlaSQL (cellParams, where) {
  const monthDate = cellParams.colDef.context.monthDate
  const accountingOrganisationId = cellParams.colDef.context?.accountingOrganisation?.id

  const targetValueType = cellParams.context?.valueBalance || 'period'
  const dbProperty = `for${targetValueType.charAt(0).toUpperCase() + targetValueType.slice(1)}`

  const targetCurrencyCode = cellParams.context.currencySymbol === 'XXX' ? cellParams.colDef.context?.accountingOrganisation?.base_currency : cellParams.context.currencySymbol

  const query = `
    SELECT 
      SUM(${dbProperty}) sumValue
    FROM ${alaSQLtableName} 
    WHERE month = ? 
    AND accounting_organisation_id = ?
    AND currencyCode = ?
    AND ${where}
  `

  // console.log('query', query)
  const result = alasql(query,
    [
      monthDate,
      accountingOrganisationId,
      targetCurrencyCode
    ])
  return result[0]?.sumValue || 0
}
