// import { createGrid } from 'ag-grid-community'
import { hideElement, setElementHtml, showElement, showLoading } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
// import { makeColumnDefs } from './make-column-defs.js'
import { appAccount } from '../../../modules/user/account.js'
import { createGrid } from 'ag-grid-community'
// import { makeRows } from './make-rows.js'
import { loadingFeedback } from './feedback-error.js'
import { makeRows } from './make-rows.js'
import { makeColumnDefs } from './make-column-defs.js'
import { loadDataMonthlyLedgerAggregates } from './load-data-monthly-ledger-aggregates.js'

let ongoingUpdateId = 0

export const context = {
  data: {}
}

export let gridApi

/**
 *
 */
export async function updateView () {
  console.log('updateView', optionSelectorData)
  showLoading('loadingMessage')
  showElement('loadingMessage')

  hideElement('action-export')

  ongoingUpdateId++

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.currencySymbol = optionSelectorData.optionsCurrencies?.symbol || 'XXX'
  context.viewDecimals = optionSelectorData.decimals || 0
  context.dates = optionSelectorData.dates
  context.accountData = appAccount.accountData()
  console.log('context', context)

  context.showValueType = 'value'
  // context.showValueType = 'changeValue'
  // context.showValueType = 'changePct'

  context.groupBy = optionSelectorData.optionsGroupBy?.selected || []

  // context.groupBy = ['accountKey', 'contactKey', 'entityKey']
  // context.groupBy = ['accountKey', 'contactKey']
  // context.groupBy = ['contactKey']
  // context.groupBy = ['accountKey']
  // context.groupBy = ['entityKey']

  showTitle(context)
  hideElement('grid')

  // Load data
  const executionUpdateId = ongoingUpdateId
  const updateFeedbackInstance = loadingFeedback()
  const response = await loadDataMonthlyLedgerAggregates(context)
  updateFeedbackInstance.stop()
  if (ongoingUpdateId !== executionUpdateId) return // API might respond for a previous request: we forget it then

  context.data = response

  if (response?.hasError) {
    hideElement('loadingMessage')
    return
  }

  hideElement('progressBar')

  showGrid(context)

  if (!context.data?.ledgerMonthlyAggregates || context.data?.ledgerMonthlyAggregates?.length === 0) {
    setElementHtml('loadingMessage', 'No values to show')
    showElement('loadingMessage')

    hideElement('grid')
    hideElement('action-export')
  } else if (Array.isArray(context.showOrgId) && context.showOrgId.length > 1 && context.currencySymbol === 'XXX') {
    setElementHtml('loadingMessage', 'Select the Currency to convert the multiple entities to')
    showElement('loadingMessage')

    hideElement('grid')
    hideElement('action-export')
  } else {
    hideElement('loadingMessage')
    showElement('action-export')
  }
}

function showGrid (context) {
  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)
  const containerHasContent = gridContainerElement.querySelectorAll('div')?.length

  const gridRows = makeRows(context)
  const gridColumns = makeColumnDefs(context)
  console.log('gridRows', gridRows)
  console.log('gridColumns', gridColumns)

  // console.log('grid', gridRows, gridColumns)
  // console.log('gridApi', gridApi, containerHasContent, gridContainerElement)

  showElement('grid')
  if (gridApi && containerHasContent) {
    // console.log('UPDATE the grid')
    gridApi.updateGridOptions({
      rowData: gridRows,
      columnDefs: gridColumns
    })
  } else {
    // console.log('CREATE the grid')
    const gridOptions = {
      rowData: gridRows,
      columnDefs: gridColumns,
      defaultColDef: {
        filter: true,
        floatingFilter: true
      },
      autoSizeStrategy: {
        type: 'fitCellContents'
      },
      // suppressMovableColumns: true,
      processUnpinnedColumns: function (params) {
        // Avoid AgGrid from shrinking the columns and moving pinned columns out of the pinned section (if it does, it changes the order of the columns)
        const { columnWidth } = params
        return columnWidth
      },
      context
    }

    gridApi = createGrid(gridContainerElement, gridOptions)
  }
  // console.log('gridApi', gridApi)
}

/**
 *
 * @param {*} context
 * @returns
 */
function showTitle (context) {
  if (Array.isArray(context.showOrgId)) {
    setElementHtml('whatsShowed', context.showOrgId.length + ' entities')
    return
  }

  const targetOrg = context.accountData._accountOrganisations.find(function (oneAccountOrg) {
    return oneAccountOrg._xeroOrganisation?._id === context.showOrgId
  })

  const entityName = targetOrg?._xeroOrganisation?.name
  setElementHtml('whatsShowed', entityName)
}
