// import dayjs from 'dayjs'
import { formatNumber } from '../../../../helpers/helpers/numbers.js'

export function makeColumnDefs (context) {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Month',
    valueGetter: function (cellParams) {
      const dateMonth = cellParams.data.month
      if (!dateMonth) return null

      return dateMonth

      // const date = dayjs(data)
      // return date.format('YYYY-MM')
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Statement',
    valueGetter: function (cellParams) {
      const account = cellParams.data.chartOfAccount
      if (!account) {
        console.warn('No account?', cellParams.data)
      }
      return account?.statement
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      const account = cellParams.data.chartOfAccount
      if (!account) {
        console.warn('No account?', cellParams.data)
      }
      return account?.name
    },
    pinned: 'left'
  })

  // Amount
  columnDefs.push({
    headerName: 'Journals',
    valueGetter: function (cellParams) {
      const journalValue = cellParams.data.journalsValues?.sum || null
      return journalValue
    },
    valueFormatter,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Trial Balances',
    valueGetter: function (cellParams) {
      const tbValue = cellParams.data.tbValues?.amount || null
      return tbValue
    },
    valueFormatter,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Journals vs. Trial Balances',
    valueGetter: function (cellParams) {
      const journalValue = cellParams.data.journalsValues?.sum || null
      const tbValue = cellParams.data.tbValues?.amount || null
      return journalValue - tbValue
    },
    valueFormatter,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Adjustments',
    valueGetter: function (cellParams) {
      const adjustment = cellParams.data.adjustments?.diff || cellParams.data.adjustments?.amount || null
      return adjustment
    },
    valueFormatter,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Ledgers',
    valueGetter: function (cellParams) {
      const amount = cellParams.data.ledgersValues?.amount || null
      return amount
    },
    valueFormatter,
    type: 'numericColumn',
    width: 120
  })

  columnDefs.push({
    headerName: 'Ledgers vs. Trial Balances',
    valueGetter: function (cellParams) {
      const amount = cellParams.data.ledgersValues?.amount || null
      const tbAmount = cellParams.data.tbValues?.amount || null
      return amount - tbAmount
    },
    valueFormatter,
    type: 'numericColumn',
    width: 120
  })

  return columnDefs
}

function valueFormatter (cellParams) {
  if (cellParams.value === null) return null
  return formatNumber(cellParams.value, '2')
}
