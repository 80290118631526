import { makeXeroLink } from '../../helpers/xero-links.js'
import { rollup } from 'd3'

export function makeColumnDefs (trackingsResponse, currencyResponse) {
  console.log(currencyResponse)

  const columnDefs = []

  columnDefs.push({
    field: 'name'
  })

  columnDefs.push({
    headerName: 'Xero',
    valueGetter: function (cellParams) {
      return makeXeroLink('Trackings', { shortCode: cellParams.data.xero_shortcode })
    },
    cellRenderer: function (cellParams) {
      return '<a href="' + cellParams.value + '" target="_blank">Open</a>'
    }
  })

  const trackingsCategoryColumns = makeTrackingsCategoryColumns(trackingsResponse)
  columnDefs.push(...trackingsCategoryColumns)

  return columnDefs
}

function makeTrackingsCategoryColumns (trackingsResponse) {
  const columnDefs = []

  const trackingsMap = rollup(trackingsResponse,
    (values) => {
      return {
        groupValues: values,
        category: {
          id: values[0].category_id,
          name: values[0].category_name
        },
        accountingOrganisation: {
          id: values[0].accounting_organisation_id
        }
      }
    },
    (d) => d.category_name,
    (d) => d.accounting_organisation_id
  )

  trackingsMap.forEach((value, key) => {
    columnDefs.push({
      headerName: key,
      valueGetter: function (cellParams) {
        const entityCategory = cellParams.data.entityTrackingsMap?.get(key)
        if (!entityCategory) return null

        if (entityCategory.category.status === 'ARCHIVED') return 'Archived'

        const numberActiveOptions = entityCategory.categoryOptions.filter(v => v.option_status === 'ACTIVE').length
        return `${numberActiveOptions} Options`
      },
      context: {
        categoryName: key
      }
    })
  })

  columnDefs.sort((a, b) => {
    return a.headerName.localeCompare(b.headerName)
  })

  return columnDefs
}
