import dayjs from 'dayjs'
import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { activateOptionSelector } from '../../../modules/option-selector/index.js'
import { appAccount } from '../../../modules/user/account.js'
import { consolidationOrg } from './compute/consolidation-data.js'

export function showSelectors (changeAction, context) {
  console.log('showSelectors')

  const optionSelectorShow = [
    'dates',
    'others'
  ]

  const accountData = appAccount.accountData()

  const dateSeriesStart = accountData?.firstDate || dayjs().subtract(48, 'months').format('YYYY-MM-DD')
  const dateseries = makeDateSerie(dateSeriesStart)

  const currenciesForAccount = context.currenciesForAccount
  // const currencies = accountData?.currencies

  const optionSelectorData = activateOptionSelector({
    containerId: 'optionSelector',
    show: optionSelectorShow,
    hide: [],
    default: 'organisations',
    onChange: changeAction,
    dateseries,
    dateOptions: {
      default: {
        from: 'relative:-5',
        to: 'relative:0'
      }
    },
    customSettings: {
      organisations: mapOrganisations(context),
      orgOptions: {
        // allowConso: true,
        enableConsolidation: consolidationOrg,
        allowMultiple: false,
        allowSeveral: true
        // defaultIndex: 0
      },
      currencies: currenciesForAccount
    }
  })

  return optionSelectorData
}

function mapOrganisations (context) {
  if (context.specificOrgId) return forNoAccount(context)

  const xeroOrgIdsAvailable = context.accountData.organisations?.map(o => o.orgXeroId)

  const organisations = []
  context.entities?.forEach(function (entity) {
    if (xeroOrgIdsAvailable.includes(entity.xero_id)) organisations.push(entity)
    // return entity
  })

  return organisations
}

/** Allowing the page to be loaded for a specific entity, without a specific account */
function forNoAccount (context) {
  return [{
    _id: context.specificOrgId,
    name: context.specificOrgId
  }]
}
