
/**
 * TODO: ensure every months is included
 */
export function makeMonthSeries (context) {
  // console.log('makeMonthSeries', context)
  const valueMonths = context.data?.monthlyValues?.map(d => d.month)

  const uniqueMonths = new Set(valueMonths)

  return Array.from(uniqueMonths)
}
