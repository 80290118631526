import { ascending } from 'd3'

export function makeRows (entitiesResponse, entitiesSyncStatsResponse) {
  if (!entitiesResponse) return []
  if (!Array.isArray(entitiesResponse)) return []
  if (entitiesResponse.length === 0) return []

  entitiesResponse.sort(function (a, b) {
    return ascending(a.name, b.name)
  })

  entitiesResponse.forEach(entity => {
    entity.syncStats = {
      //
      latestDone: entitiesSyncStatsResponse?.latestDone?.find(syncStats => syncStats.accounting_organisation_id === entity.id),
      //
      ongoing: entitiesSyncStatsResponse?.ongoing?.find(syncStats => syncStats.accounting_organisation_id === entity.id),
      //
      nextScheduled: entitiesSyncStatsResponse?.nextScheduled?.find(syncStats => syncStats.accounting_organisation_id === entity.id)
    }
  })

  return entitiesResponse
}
