import { callApi } from '../../helpers/fetch.js'
import { updateRows } from './make-rows.js'

//
export async function associateCurrency (mongoAccountId, cellParams, options = {}) {
  cellParams.data.isLoading = true
  cellParams.api.refreshCells({
    rowNodes: [cellParams.node],
    columns: [cellParams.colDef],
    force: true
  })

  const apiUrl = '/currencies/associate/:mongoAccountId/:currencyCode/:associate/:isDefault'

  const isCurrentlyInUse = cellParams.data.AppAccountCurrencies?.length > 0

  const currencyResponse = await callApi(apiUrl, {
    mongoAccountId,
    currencyCode: cellParams.data.code,
    associate: !isCurrentlyInUse,
    isDefault: options.isDefault
  }, {
    method: 'PUT'
  })
  // console.log('currencyResponse', currencyResponse)

  const updatedRows = updateRows(currencyResponse, cellParams.api.getGridOption('rowData'))
  cellParams.api.setGridOption('rowData', updatedRows)

  cellParams.data.isLoading = false
  cellParams.api.refreshCells()

  return currencyResponse
}
