
export function roundNumber (value, decimals) {
  const roundingDecimals = Number.isFinite(decimals) ? decimals : 2

  value = prepareValue(value)

  return parseFloat(value.toFixed(roundingDecimals))
}

function prepareValue (value) {
  if (!Number.isFinite(value)) {
    value = parseFloat(value)
  }

  return value
}
