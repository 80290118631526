import dayjs from 'dayjs'

/**
 * TODO: ensure every months is included
 */
export function makeMonthSeries (context) {
  console.log('makeMonthSeries', context)

  return makeMonthSeriesBetweenDates(context.dates?.min, context.dates?.max)
}

function makeMonthSeriesBetweenDates (dateMin, dateMax) {
  const months = []

  let rollingMonth = dateMin ? dayjs(dateMin).startOf('month') : dayjs().startOf('month')
  const lastMonth = dateMax ? dayjs(dateMax).endOf('month') : dayjs().endOf('month')

  while (rollingMonth.isSameOrBefore(lastMonth, 'month')) {
    months.push(rollingMonth.format('YYYY-MM-DD'))
    rollingMonth = rollingMonth.add(1, 'month')
  }

  return months
}

export function monthsFromValues (valuesArray) {
  const valueMonths = valuesArray?.map(d => d.month)

  const uniqueMonths = new Set(valueMonths)

  return Array.from(uniqueMonths)
}
