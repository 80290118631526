import { sum } from 'd3'
import { makeTargetChartOfAccountIds } from './make-target-chart-of-accounts-ids.js'
import { zenDb } from '../index-values-db.js'

const DEBUG = false

export function computeFromGroupedMap (cellParams) {
  const accountingOrganisationId = cellParams.colDef.context?.accountingOrganisation?.id

  const targetCurrencyCode = cellParams.context.currencySymbol === 'XXX' ? cellParams.colDef.context?.accountingOrganisation?.base_currency : cellParams.context.currencySymbol

  const targetChartOfAccountIds = makeTargetChartOfAccountIds(cellParams)

  const monthDate = cellParams.colDef.context.monthDate

  const targetValueType = cellParams.context.valueBalance || 'period'
  const dbProperty = `for${targetValueType.charAt(0).toUpperCase() + targetValueType.slice(1)}`

  const relevantValues = []

  targetChartOfAccountIds.forEach(function (targetChartOfAccountId) {
    const values = zenDb.map
      .get(accountingOrganisationId)
      ?.get(targetCurrencyCode)
      ?.get(targetChartOfAccountId)
      ?.get(monthDate)
    // console.log('values', values)
    if (!values) return

    relevantValues.push(...values)
  })

  const computedValue = sum(relevantValues, d => d[dbProperty])

  const showDebug = DEBUG || cellParams.context.showDebug
  if (showDebug) {
    console.log('zenDb.map', zenDb.map)
    console.log('accountingOrganisationId, monthDate, targetCurrencyCode, targetChartOfAccountIds', accountingOrganisationId, monthDate, targetCurrencyCode, targetChartOfAccountIds)
    console.log('zenDb.map.get(accountingOrganisationId)', zenDb.map.get(accountingOrganisationId))
    console.log('computedValue', computedValue)
  }

  return computedValue
}
