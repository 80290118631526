import dayjs from 'dayjs'
import { makeDateSerie } from '../../../../helpers/dates/make-date-serie.js'
import { activateOptionSelector } from '../../../modules/option-selector/index.js'
import { appAccount } from '../../../modules/user/account.js'
import { context } from './update-view.js'

export function showSelectors (changeAction) {
  console.log('showSelectors')

  const optionSelectorShow = [
    'dates'
  ]

  const accountData = appAccount.accountData()

  const dateSeriesStart = context.specificOrgId ? dayjs().subtract(48, 'months').format('YYYY-MM-DD') : accountData?.firstDate
  const dateseries = makeDateSerie(dateSeriesStart)
  const currencies = accountData?.currencies

  const optionSelectorParams = activateOptionSelector({
    containerId: 'optionSelector',
    show: optionSelectorShow,
    hide: [],
    default: 'organisations',
    onChange: changeAction,
    dateseries,
    dateOptions: {
      default: {
        from: 'relative:-2',
        to: 'relative:0'
      }
    },
    customSettings: {
      organisations: mapOrganisations(),
      orgOptions: {
        allowConso: true,
        allowMultiple: false
        // defaultIndex: 0
      },
      currencies
    }
  })
  return optionSelectorParams
}

function mapOrganisations () {
  if (context.specificOrgId) return forNoAccount(context)
  const accountData = appAccount.accountData()
  // console.log('accountData', accountData)

  const organisations = accountData?._accountOrganisations?.map(function (accountOrganisation) {
    return accountOrganisation._xeroOrganisation
  })
  return organisations
}

/** Allowing the page to be loaded for a specific entity, without a specific account */
function forNoAccount (context) {
  return [{
    _id: context.specificOrgId,
    name: context.specificOrgId
  }]
}
