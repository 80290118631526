
/**
 * Makes a single map of combining multiple arrays of objects
 * @param {Object[]}} arrays
 * @param {Function} keyFunction
 * @param {Function} additionalPropertiesFunction
 * TODO: add a function for default values? Can be done through the additionalPropertiesFunction, but it could be clearer
 * @returns
 */
export function combineGroup (arrays, keyFunction, additionalPropertiesFunction) {
  const map = new Map()
  arrays?.forEach(function (array) {
    array.values?.forEach(function (value) {
      const mapEntry = getMapEntry(map, keyFunction, value)
      mapEntry[array.output] = value
      additionalPropertiesFunction(value, mapEntry)
    })
  })
  return map
}

function getMapEntry (map, keyFunction, value) {
  const key = keyFunction(value)

  const existingEntry = map.get(key)
  if (existingEntry) return existingEntry

  const entry = {}
  map.set(key, entry)
  return entry
}
