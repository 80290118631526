
// App config
import * as configVar from '../../../config/config.js'
import { showElement } from '../../../helpers/dom.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'
import { loadOrgUsers } from '../org-info/org-users/load-org-users.js'

// Page Module
import htmlIndex from './index.html'
import { showDataCoverage } from './show-data-coverage.js'
import { showEntityRecordsCount } from './show-entity-records-count.js'
import { showJournalsIssues } from './show-journals-issues.js'
import { showSyncManagement } from './sync-management/show-sync-management.js'

/**
 *
 * @returns
 */
export async function showOrgPostgresInfos (orgId) {
  console.log('showOrgPostgresInfos show()', orgId)

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showHeader(orgId)

  showElement('organisationSyncManagement')
  showSyncManagement(orgId)

  showJournalsIssues(orgId)
  showEntityRecordsCount(orgId)
  showDataCoverage(orgId)
}

async function showHeader (orgId) {
  const organisationAccess = await loadOrgUsers(orgId)

  const containerElement = showElement('organisationOverallDetails')
  fillHtml(containerElement, '[d-data="organisationName"]', organisationAccess.organisation.name)
  fillHtml(containerElement, '[d-data="linkToPnL"]', `<a href="/app/financials/o/${orgId}/pnl" target="_blank">PnL</a>`)
  fillHtml(containerElement, '[d-data="linkToBS"]', `<a href="/app/financials/o/${orgId}/bs" target="_blank">Balance Sheet</a>`)
}

function fillHtml (containerElement, querySelector, html) {
  const targetElements = containerElement.querySelectorAll(querySelector)
  targetElements.forEach(function (oneElement) {
    oneElement.innerHTML = html
  })
}
