import dayjs from 'dayjs'
import { formatNumber } from '../../../../helpers/helpers/numbers.js'
import { appAccount } from '../../../modules/user/account.js'
import { makeXeroLink } from '../../../helpers/xero-links.js'

export function makeColumnDefs (context) {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Date',
    valueGetter: function (cellParams) {
      return dayjs(cellParams.data.journalDate).format('YYYY-MM-DD')
    },
    valueFormatter: function (cellParams) {
      return dayjs(cellParams.value).format('DD MMM YYYY')
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Entities Pair',
    valueGetter: function (cellParams) {
      return cellParams.data.entitiesPairName
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Entity',
    valueGetter: function (cellParams) {
      const transactionOrg = accessOrgData(context, cellParams.data)
      return transactionOrg?.name
    }
  })

  columnDefs.push({
    headerName: 'Contact',
    valueGetter: function (cellParams) {
      const contact = accessContactData(context, cellParams.data)
      return contact?.name || '??'
    },
    cellRenderer: function (cellParams) {
      const contact = accessContactData(context, cellParams.data)
      const transactionOrg = accessOrgData(context, cellParams.data)

      const transactionDate = dayjs(cellParams.data.journalDate).format('YYYY-MM-DD')

      const link = makeXeroLink('Contact', {
        shortCode: transactionOrg?.details?.shortCode,
        xeroContactId: contact?.xeroContactId,
        fromISOdate: transactionDate,
        toISOdate: transactionDate
      })

      return `${cellParams.value} (<a href="${link}" target="_blank">Xero</a>)`
    }
  })

  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      const transactionChartOfAccountId = cellParams.data._chartOfAccount
      const chartOfAccount = context.accountData.chartOfAccounts.indexed[transactionChartOfAccountId]
      return chartOfAccount?.name
    }
  })

  columnDefs.push({
    headerName: 'Amount',
    valueGetter: function (cellParams) {
      // Values are debit positive; we make it Credit Positive
      const multiplier = -1
      const amount = cellParams.data.value
      return amount * multiplier
    },
    valueFormatter: function (cellParams) {
      return formatNumber(cellParams.value, context.decimals || 0)
    },
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Interco #',
    valueGetter: function (cellParams) {
      return cellParams.data.intercoTransactionId
    }
  })

  columnDefs.push({
    headerName: 'Analysis',
    valueGetter: function (cellParams) {
      return cellParams.data.analysis?.issueMessage || 'Ok'
    },
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Interco Values',
    valueGetter: function (cellParams) {
      if (cellParams.data.analysis?.issue === null) {
        return `${cellParams.data.analysis?.amounts[0]} in both entities`
      }

      return (cellParams.data.analysis?.amounts[0] || 0) + ' vs. ' + (cellParams.data.analysis?.amounts[1] || 0)
    },
    type: 'numericColumn'
  })

  return columnDefs
}

function accessContactData (context, cellData) {
  const transactionContactId = cellData._contact
  const contact = context.crossContacts.find(contact => contact._id === transactionContactId)
  return contact
}

function accessOrgData (context, cellData) {
  const orgId = cellData._xeroOrganisation
  const org = appAccount.getOrganisationData(orgId)
  return org
}
