import { callApi } from '../../../helpers/fetch.js'

export async function loadDataStatements (context) {
  console.log('loadDataStatements', context)
  const orgId = context.showOrgId
  if (!orgId) return

  // const url = `/api/finlogic/data/${orgId}/pg-statement-values/${context.dates?.min}/${context.dates?.max}/${context.currencySymbol}`
  // const loadedData = await callApi(url)

  const url = '/api/finlogic/data/:orgId/pg-statement-values/:monthFrom/:monthTo/:currencyCode'

  const loadedData = await callApi(url,
    {
      orgId,
      monthFrom: context.dates?.min,
      monthTo: context.dates?.max,
      currencyCode: context.currencySymbol
    }
  )

  console.log('loadDataStatements loadedData', loadedData)

  return loadedData
}
