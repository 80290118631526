import { createGrid } from 'ag-grid-community'
import { makeColumnDefs } from './make-column-defs.js'
import { makeRows } from './make-rows.js'
import { menuOptions } from './show-menu.js'

let gridApi

export const viewParams = {}

export function updateView () {
  const rows = makeRows(viewParams.chartOfAccountResponse, menuOptions)
  // console.log('rows', rows)

  const columnDefs = makeColumnDefs(viewParams.chartOfAccountResponse)
  // console.log(columnDefs)

  if (gridApi) {
    gridApi.updateGridOptions({
      rowData: rows,
      columnDefs
    })
  } else {
    const gridOptions = {
      pinnedTopRowData: [{ row: 'linkToXero' }],
      rowData: rows,
      columnDefs,
      defaultColDef: {
        filter: true,
        floatingFilter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true
      },
      context: {
        menuOptions,
        chartOfAccountResponse: viewParams.chartOfAccountResponse
      },
      overlayNoRowsTemplate: 'No accounts matching',
      // autoSizeStrategy: {
      //   type: 'fitCellContents'
      // },
      onCellClicked: function (cellParams) {
        console.log('onCellClicked', cellParams)
      }
    }

    const containerId = 'chartOfAccountGrid'
    const gridContainerElement = document.getElementById(containerId)
    console.log('gridContainerElement', gridContainerElement)
    gridApi = createGrid(gridContainerElement, gridOptions)
    console.log('grid created', gridApi)
  }
}
