
export function formatMemoryUsage (memoryUsage) {
  const units = ['Bytes', 'KB', 'MB', 'GB']
  // console.log('memoryUsage', memoryUsage)
  let unitIndex = 0
  let sizeDivided = memoryUsage

  const divider = 1024

  while (sizeDivided >= divider) {
    sizeDivided = sizeDivided / divider
    unitIndex++
  }

  const size = sizeDivided.toFixed(1)
  return `${size} ${units[unitIndex]}`
}

export function bitesToMB (bites) {
  if (!Number.isFinite(bites)) return bites
  const inMb = bites / (1024 * 1024)
  return parseFloat(inMb.toFixed(1))
}
