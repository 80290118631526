import { fillString } from '../../helpers/templating/string-template.js'
import { makeXeroLink } from './xero-links.js'
import { makeSourceName } from './make-source-name.js'

/**
 *
 * @param {Object} sourceData
 * @param {String} sourceData.xeroSourceType
 * @param {String} sourceData.xeroSourceId
 * @param {String} sourceData.typeApi
 * @param {String} [sourceData.bankTransferFrom]
 * @param {String} [sourceData.bankTransferTo]
 * @param {String} xeroShortcode
 * @param {Object} journalEntryData - used for the chart of account of bank transfers
 * @returns
 */
export function linkToSource (sourceData, xeroShortcode, journalEntryData) {
  console.log('linkToSource', sourceData, xeroShortcode, journalEntryData)
  const sourceType = sourceData?.xeroSourceType
  const sourceId = sourceData?.xeroSourceId

  if (!sourceId) {
    // console.log('linkToSource, no sourceId:', sourceData)
    return sourceType
  }

  if (sourceData.typeApi === 'BankTransfer') {
    return forTransfer(sourceData, xeroShortcode, journalEntryData)
  }

  const xeroSourceType = sourceData.xeroSourceType
  let xeroLinkType = xeroSourceType
  if (sourceData.typeApi === 'ManualJournal') {
    xeroLinkType = sourceData.typeApi
  }

  const linkToXero = fullHtmlLink(xeroLinkType, sourceId, xeroShortcode)

  // The full text
  const outputTemplate = ':sourceName [:linkToXero]'

  return fillString(outputTemplate, {
    sourceName: makeSourceName(sourceData),
    linkToXero
  })
}

function fullHtmlLink (xeroSourceType, sourceId, xeroShortcode) {
  // The link to Xero
  const urlToXero = makeXeroLink(xeroSourceType, {
    shortCode: xeroShortcode,
    sourceId
  })
  const linkToXeroTemplate = '<a href=":urlToXero" target="_blank">Xero</a>'
  const linkToXero = fillString(linkToXeroTemplate, {
    urlToXero
  })
  return linkToXero
}

function forTransfer (sourceData, xeroShortcode, journalEntryData) {
  console.log('forTransfer', sourceData, journalEntryData)

  const journalEntryCoAccount = journalEntryData?.journalLine?._chartOfAccount
  let transferData
  let transferSide = null
  switch (journalEntryCoAccount) {
    case sourceData.bankTransferFrom?._chartOfAccount:
      transferData = sourceData.bankTransferFrom
      transferSide = 'SEND'
      break
    case sourceData.bankTransferTo?._chartOfAccount:
      transferData = sourceData.bankTransferTo
      transferSide = 'RECEIVE'
      break
  }

  console.log(transferSide, transferData)

  const linkToXero = fullHtmlLink('BankTransaction', transferData?.xeroBankTransactionId, xeroShortcode)
  console.log('linkToXero', linkToXero)

  // The full text
  const outputTemplate = ':transferSide > :sourceName [:linkToXero]'

  const transferText = fillString(outputTemplate, {
    transferSide,
    sourceName: makeSourceName(sourceData),
    linkToXero
  })
  console.log(transferText)
  return transferText
}
