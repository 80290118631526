import { fillString } from '../../../../helpers/templating/string-template.js'
import { callApi } from '../../../helpers/fetch.js'

/**
 * filterDates
 *  journalDates=[from,to]
 *
 * @param {*} context
 * @param {String} context.showOrgId
 * @returns
 */
export async function loadDataJournals (context) {
  console.log('loadDataJournals', context)
  let options = 'journalDates=:journalsFrom,:journalsTo&recordingDates=:recordingFrom,:recordingTo'

  options = fillString(options, {
    journalsFrom: context.filterDates?.journals?.from || 'notSet',
    journalsTo: context.filterDates?.journals?.to || 'notSet'
  })
  options = fillString(options, {
    recordingFrom: context.filterDates?.records?.from || 'notSet',
    recordingTo: context.filterDates?.records?.to || 'notSet'
  })

  const orgId = context.showOrgId
  const loadedData = await callApi('/api/finlogic/data/:orgId/journals-entries?:options', { orgId, options })
  return loadedData
}
