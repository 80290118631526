import alasql from 'alasql'
// import { generateRandomString } from '../../../../helpers/helpers/random.js'

export const alaSQLtableName = 'valuesDB'

export function loadDataAlaSQL (valuesBD) {
  console.log('loadDataAlaSQL')

  alasql(`CREATE TABLE IF NOT EXISTS ${alaSQLtableName}`)
  alasql(`CREATE INDEX idx_month ON ${alaSQLtableName} (month)`)
  alasql(`CREATE INDEX idx_accounting_organisation_id ON ${alaSQLtableName} (accounting_organisation_id)`)
  alasql(`CREATE INDEX idx_currencyCode ON ${alaSQLtableName} (currencyCode)`)
  alasql(`CREATE INDEX idx_accounting_chart_of_account_id ON ${alaSQLtableName} (accounting_chart_of_account_id)`)
  alasql(`CREATE INDEX idx_chartOfAccount_id ON ${alaSQLtableName} (chartOfAccount->id)`)

  alasql(`CREATE INDEX idx_month_accounting_organisation_id_currencyCode_chartOfAccount_id ON ${alaSQLtableName} (month, accounting_organisation_id, currencyCode, accounting_chart_of_account_id)`)
  alasql(`CREATE INDEX idx_month_accounting_organisation_id_currencyCode_chartOfAccount_id ON ${alaSQLtableName} (month, accounting_organisation_id, currencyCode, chartOfAccount->id)`)
  // Reset the table
  // alasql(`DELETE FROM ${alaSQLtableName}`)

  insertDataAlaSQL(valuesBD)
}

function insertDataAlaSQL (valuesDB) {
  // console.log('start insertDataAlaSQL; all values:', valuesDB)

  // Delete previod data of entity+currency
  const includedEntitiesIds = valuesDB?.map(d => `'${d.accounting_organisation_id}'`).join(',')
  alasql(`
    DELETE FROM ${alaSQLtableName} 
    WHERE accounting_organisation_id IN (${includedEntitiesIds})
    AND currencyCode = '${valuesDB[0]?.currencyCode}'
  `)
  // console.log('deleted previous entity+currency data')

  const valuesToInsert = valuesDB.filter(function (value) {
    if (value.chartOfAccount?.system_account === 'RETAINEDEARNINGS') return false

    return true
  })
  // console.log('values to insert:', valuesToInsert)

  console.time('insertDataAlaSQL')
  alasql(`INSERT INTO ${alaSQLtableName} SELECT * FROM ?`, [valuesToInsert])
  console.timeEnd('insertDataAlaSQL')

  selectAllalaSQL()
  // testManyQueries()
}

export function selectAllalaSQL () {
  console.time('selectAllalaSQL')
  const alasqlData = alasql(`SELECT * FROM ${alaSQLtableName}`)
  console.log('selectAllalaSQL length', alasqlData.length)
  console.log('selectAllalaSQL[0]', alasqlData[0])
  console.timeEnd('selectAllalaSQL')

  return alasqlData
}
