
// Xero uses Positives for Debit numbers.
// We pass a Multiplier for visualisation

/*
eg type:
{
  statement: 'bs', // bs, pnl
  id: 'BANK', // to match xero TYPES
  name: 'Bank',
  expectedSign: 1,
  viewSign: 1, // The view should multiply expectedSign*viewSign to know the multiplier
  oppositeSign: {
    name: 'Bank Overdraft',
    values: 'cumuls'
  },
  fxBase: 'balance', // period, history
  fxRevaluate: false // Boolean : only Equity and Earnings: ie Earnings include the impact on all other accounts anyway!
})
*/

export const accountTypes = []
accountTypes.push({
  statement: 'pnl',
  id: 'REVENUE',
  name: 'Revenue',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'SALES',
  name: 'Sales',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'DIRECTCOSTS',
  name: 'Direct Costs',
  expectedSign: 1,
  viewSign: -1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'OTHERINCOME',
  name: 'Other Income',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'EXPENSE',
  name: 'Expenses',
  expectedSign: 1,
  viewSign: -1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'OVERHEADS',
  name: 'Overheads',
  expectedSign: 1,
  viewSign: -1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'WAGESEXPENSE',
  name: 'Wages Expenses',
  expectedSign: -1,
  viewSign: -1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'SUPERANNUATIONEXPENSE',
  name: 'Super Annuation Expense',
  expectedSign: -1,
  viewSign: -1,
  fxBase: 'period',
  fxRevaluate: false
})

accountTypes.push({
  statement: 'pnl',
  id: 'DEPRECIATN',
  name: 'Depreciation',
  expectedSign: 1,
  viewSign: -1,
  fxBase: 'period',
  fxRevaluate: false
})

// Special: zenflow calculated
accountTypes.push({
  statement: 'pnl',
  id: 'ELIMINATION_DELTA_PNL',
  name: 'Elimination Deltas',
  expectedSign: 1,
  viewSign: -1,
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'pnl',
  id: 'EARNINGS_PNL',
  name: 'Earnings',
  expectedSign: 1, // Profit means more Credit than Debit; so the Offseting value is a Debit
  viewSign: 1, // A profit means a Debit (to offset the PnL), which is good and should be shown positive
  fxBase: 'period',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'FX_IMPACT_PNL',
  name: 'Translation Reserve',
  expectedSign: -1,
  viewSign: 1
})

// **********
accountTypes.push({
  statement: 'bs',
  id: 'BANK',
  name: 'Bank',
  expectedSign: 1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false,
  oppositeSign: {
    name: 'Bank Overdraft',
    values: 'cumuls'
  }
})
accountTypes.push({
  statement: 'bs',
  id: 'CURRENT',
  name: 'Current Assets',
  expectedSign: 1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'PREPAYMENT',
  name: 'Prepayments',
  expectedSign: 1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'INVENTORY',
  name: 'Inventory',
  expectedSign: 1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'FIXED',
  name: 'Fixed Assets',
  expectedSign: 1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'NONCURRENT',
  name: 'Non Current Assets',
  expectedSign: 1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})

accountTypes.push({
  statement: 'bs',
  id: 'CURRLIAB',
  name: 'Current Liabilities',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'PAYGLIABILITY',
  name: 'Wages Liabilities',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'SUPERANNUATIONLIABILITY',
  name: 'Super Annuation Liabilities',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'LIABILITY',
  name: 'Liabilities',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})
accountTypes.push({
  statement: 'bs',
  id: 'TERMLIAB',
  name: 'Term Liabilities',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})

accountTypes.push({
  statement: 'bs',
  id: 'EQUITY',
  name: 'Equity',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'history',
  fxRevaluate: true
})
//
// Special: zenflow calculated
accountTypes.push({
  statement: 'bs',
  id: 'EARNINGS_BS',
  name: 'Earnings',
  expectedSign: -1, // Profit means more Credit than Debit; so the Offseting value is a Debit; In line with other equity accounts
  viewSign: 1, // A profit means a Debit (to offset the PnL), which is good and should be shown positive
  // fxBase: 'history',
  fxRevaluate: true
})
accountTypes.push({
  statement: 'bs',
  id: 'FX_IMPACT_BS',
  name: 'Translation Reserve',
  expectedSign: -1,
  viewSign: 1
})
accountTypes.push({
  statement: 'bs',
  id: 'ELIMINATION_DELTA_BS',
  name: 'Elimination Deltas',
  expectedSign: -1,
  viewSign: 1,
  fxBase: 'balance',
  fxRevaluate: false
})

export const indexedAccountTypes = {}
accountTypes.forEach(function (oneAccountType) {
  indexedAccountTypes[oneAccountType.id] = oneAccountType
})
