import { ascending, descending } from 'd3'

export function makeRows (currencyResponse) {
  if (!currencyResponse) return []
  if (!Array.isArray(currencyResponse)) return []
  if (currencyResponse.length === 0) return []

  currencyResponse.sort(function (a, b) {
    return descending(a.AppAccountCurrencies.length, b.AppAccountCurrencies.length) ||
      descending(a.accounting_organisations.length, b.accounting_organisations.length) ||
      ascending(a.code, b.code)
  })

  return currencyResponse
}

export function updateRows (currencyResponse, currentRows) {
  if (!currencyResponse) return []
  if (!Array.isArray(currencyResponse)) return []
  if (currencyResponse.length === 0) return []

  currentRows.forEach(row => {
    const currencyRow = currencyResponse.find(c => c.id === row.id)
    if (currencyRow) {
      row.AppAccountCurrencies = currencyRow.AppAccountCurrencies
    }
  })

  return currentRows
}
