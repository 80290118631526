import { ascending, group, index } from 'd3'

export function makeRows (context) {
  console.log('makeRows', context)

  const rows = groupValuesByRow(context)

  const includedRows = includeOnlyVisibleRows(rows, context)

  // Sort
  includedRows.sort(function (r1, r2) {
    return ascending(r1.chartOfAccount?.code, r2.chartOfAccount?.code)
  })

  return includedRows
}

function includeOnlyVisibleRows (rows, context) {
  return rows

  // const monthsArray = makeMonthSeries(context)

  // const includedRows = []

  // rows?.forEach(function (row) {
  //   let isRowToInclude = false

  //   monthsArray?.forEach(function (targetMonth) {
  //     const cellValue = valueMaker(row, targetMonth, context)
  //     if (cellValue) isRowToInclude = true
  //   })

  //   if (isRowToInclude) includedRows.push(row)
  // })

  // return includedRows
}

/**
 *
 * @param {*} context
 * @returns
 */
function groupValuesByRow (context) {
  console.log('groupValuesByRow', context)

  // Identify target chart of accounts
  const targetChartOfAccounts = context?.data?.chartOfAccounts?.filter(d => d.statement === context.statementType)
  const targetChartOfAccountsIds = targetChartOfAccounts.map(d => d.id)
  // console.log('targetChartOfAccounts', targetChartOfAccounts)

  const mapOfChartOfAccounts = index(context?.data?.chartOfAccounts, d => d.id)
  // console.log('mapOfChartOfAccounts', mapOfChartOfAccounts)

  // Filter monthly values by target chart of accounts
  const filteredValues = context?.data?.monthlyValues?.filter(function (d) {
    return targetChartOfAccountsIds.includes(d.accounting_chart_of_account_id)
  })
  // console.log('filteredValues', filteredValues)

  // Group by chart of account
  const mapByChartOfAccount = group(filteredValues, d => d.accounting_chart_of_account_id)
  // console.log('mapByChartOfAccount', mapByChartOfAccount)

  // Make rows: turn map into array
  // TODO: compute stats: total, month with values, ...
  const rows = Array.from(mapByChartOfAccount.entries(), function ([key, value]) {
    return {
      key,
      values: value,
      chartOfAccount: mapOfChartOfAccounts.get(key)
    }
  })
  console.log('rows', rows)

  return rows
}
