import dayjs from 'dayjs'
import { callApi } from '../../../helpers/fetch.js'
import { setElementHtml, showElement } from '../../../helpers/dom.js'
import { index } from 'd3'

/**
 *
 * @param {Object} context
 * @param {String} context.showOrgId
 * @param {Object} [context.dates]
 * @param {String} [context.dates.min]
 * @param {String} [context.dates.max]
 * @returns
 */
export async function loadDataMonthlyLedgerAggregates (context) {
  console.log('loadDataMonthlyLedgerAggregates')
  console.log('context.showOrgId', context.showOrgId)

  if (!context.showOrgId) return

  // The orgIds to fetch data for
  const orgIds = []
  if (!Array.isArray(context.showOrgId)) {
    orgIds.push(context.showOrgId)
  } else {
    orgIds.push(...context.showOrgId)
    document.getElementById('progressBar')?.setAttribute('value', 0)
  }

  // Load the data for each entity
  const promises = []
  for (const orgId of orgIds) {
    const orgData = getOrgData(context.accountData?._accountOrganisations, orgId)

    const promise = loadOneEntity(orgId, context.dates, orgData, {
      numberProgressStep: orgIds.length
    })
    promises.push(promise)
  }

  const results = {
    ledgerMonthlyAggregates: [],
    chartOfAccounts: [],
    contacts: [],
    trackings: []
  }

  try {
    const loadedData = await Promise.all(promises)

    // Concatene the values together
    loadedData.forEach(function (oneOrgData) {
      results.ledgerMonthlyAggregates = results.ledgerMonthlyAggregates.concat(oneOrgData.ledgerMonthlyAggregates)
      results.chartOfAccounts = results.chartOfAccounts.concat(oneOrgData.chartOfAccounts)
      results.contacts = results.contacts.concat(oneOrgData.contacts)
      results.trackings = results.trackings.concat(oneOrgData.trackings)
    })
  } catch (error) {
    console.warn('error', error)
    results.hasError = true
  }

  // Return
  console.log('results', results)
  return results
}

/**
 *
 * @param {String} orgId
 * @param {Object} dates
 * @param {String} dates.min
 * @param {String} dates.max
 * @param {Object} orgData
 * @param {Object} otherParams
 * @returns
 */
async function loadOneEntity (orgId, dates, orgData, otherParams) {
  console.log('loadOneEntity', dates)
  const monthFrom = dates.min || dayjs().add(-1, 'month').format('YYYY-MM')
  const monthTo = dates.max || dayjs().format('YYYY-MM')
  console.log('monthFrom', monthFrom, 'monthTo', monthTo)

  try {
    const url = `/api/finlogic/data/${orgId}/pg-ledger-monthly-aggregates/${monthFrom}/${monthTo}`
    const loadedData = await callApi(url)

    const chartOfAccountsMap = index(loadedData.chartOfAccounts, d => d.id)
    const contactsMap = index(loadedData.contacts, d => d.id)
    const trackingOptionsMap = index(loadedData.trackings, d => d.tracking_option_id)

    // Add the org info to each value
    loadedData?.ledgerMonthlyAggregates?.forEach(function (oneValue) {
      oneValue.entity = orgData
      oneValue.currencySymbol = orgData.details?.baseCurrency

      oneValue.chartOfAccount = chartOfAccountsMap.get(oneValue.accounting_chart_of_account_id)
      oneValue.contact = contactsMap.get(oneValue.accounting_contact_id)

      oneValue.tracking1 = trackingOptionsMap.get(oneValue.tracking_option_1)
      oneValue.tracking2 = trackingOptionsMap.get(oneValue.tracking_option_2)

      oneValue.monthFull = oneValue.month
      oneValue.month = oneValue.month.substring(0, 7)
    })

    console.log('loadedData', orgData, loadedData)

    if (otherParams.numberProgressStep > 1) {
      showElement('progressBar')
      const progressBar = document.getElementById('progressBar')
      const currentValue = progressBar?.getAttribute('value')

      const oneStepSize = 100 / otherParams.numberProgressStep
      const newValue = +currentValue + oneStepSize
      document.getElementById('progressBar')?.setAttribute('value', newValue)
    }

    return loadedData
  } catch (error) {
    console.warn('error', error)

    setElementHtml('feedbackMessage', 'Oops, an error occured. Refresh the page and try again')
    showElement('feedbackMessage')
    throw new Error(error)
  }
}

function getOrgData (accountDataAccountOrg, orgId) {
  const org = accountDataAccountOrg?.find(function (one) {
    return one._xeroOrganisation._id === orgId
  })

  return org?._xeroOrganisation
}
