
export function outputChartOfAccountText (data) {
  console.log('data', data)
  const code = data?.chart_of_account_code
  const name = data?.chart_of_account_name

  const output = [name]
  if (code) output.unshift('[' + code + ']')

  return output.join(' ')
}
