import { callApi } from '../helpers/fetch.js'

//
export async function loadCurrencies (mongoAccountId) {
  const apiUrl = '/currencies/view/currency-list-account/:mongoAccountId'
  const data = await callApi(apiUrl, {
    mongoAccountId
  })
  // console.log(data)

  return data
}
