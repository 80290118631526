
export function makeKeys (oneEntry) {
  const entityKey = oneEntry.entity?.name || 'no Entity Name'
  const accountKey = oneEntry.chartOfAccount?.name || 'noAccountName'

  const contactKey = makeContactKey(oneEntry)

  return {
    accountKey,
    contactKey,
    entityKey
  }
}

function makeContactKey (oneEntry) {
  if (oneEntry.contact?.name) return oneEntry.contact?.name

  if (oneEntry.chartOfAccount?.name === 'Bank Revaluations') {
    return '... Bank Revaluations'
  }

  return 'no ContactName'
}
