import { createGrid, themeQuartz } from 'ag-grid-community'
import { hideElement, showElement, showLoading } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadDataStatements } from './load-data.js'

import { makeGridData } from './make-grid-data.js'
import { makeRowPinnedTop } from './pinned-row-top.js'
import { consolidationOrg } from './compute/consolidation-data.js'

export const context = {
  data: {}
}

export let gridApi

const myTheme = themeQuartz.withParams({
  pinnedColumnBorder: 'none',
  rowBorder: 'none',
  wrapperBorder: 'none'
})

/**
 *
 */
export async function updateView () {
  console.log('updateView', optionSelectorData, context)
  showElement('statementLoadingMessage')
  hideElement('statementContainer')
  showLoading('statementLoadingMessage')

  // Entities included: 1, or multiple for consolidation
  context.includedEntityIds = optionSelectorData.organisationId

  if (context.includedEntityIds?.[0] === consolidationOrg.id) {
    // TODO: add eliminations when needed

    const xeroOrgIdsAvailable = context.accountData.organisations?.map(o => o.orgXeroId)

    const organisations = []
    context.entities?.forEach(function (entity) {
      if (xeroOrgIdsAvailable.includes(entity.xero_id)) organisations.push(entity)
    // return entity
    })

    // context.includedEntityIds = [...context.entities.map(e => e.id)]
    context.includedEntityIds = [...organisations.map(e => e.id)]
  }

  // Which entity to show: 1, or detailed
  // const layout = 'consolidation'
  context.entitiesLayout = optionSelectorData.entitiesLayout || 'consolidated'
  if (context.includedEntityIds?.length > 1) {
    if (context.entitiesLayout === 'consolidated') {
      context.showingEntitiesIds = [consolidationOrg.id]
    } else {
      context.showingEntitiesIds = [...context.includedEntityIds, consolidationOrg.id]
    }
  } else {
    context.showingEntitiesIds = context.includedEntityIds
  }
  console.log(['showingEntitiesIds', context.showingEntitiesIds, 'includedEntityIds', context.includedEntityIds])

  if (context.includedEntityIds?.length > 1 && (!optionSelectorData.currencySymbol || optionSelectorData.currencySymbol === 'XXX')) {
    const defaultCurrency = context.currenciesForAccount.find(c => c.is_default === true)
    const defaultCurrencyCode = defaultCurrency?.code

    optionSelectorData.currencySymbol = defaultCurrencyCode || context.entities?.[0]?.base_currency
  }
  context.currencySymbol = optionSelectorData.currencySymbol || 'XXX'

  context.dates = optionSelectorData.dates

  context.viewDecimals = optionSelectorData.decimals || 0
  context.timeDirection = optionSelectorData.timeDirection || 'ascending'

  context.grid = context.grid || {}
  context.grid.openedGroups = context.grid.openedGroups || new Set()

  // Load data
  await loadDataStatements(context)
  console.log('context', context)
  // context.data = response

  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)
  const containerHasContent = gridContainerElement.querySelectorAll('div')?.length

  console.time('show the grid')

  const gridData = makeGridData(context)

  const gridRows = gridData.rows
  const gridColumns = gridData.columns
  // console.log('grid', gridRows, gridColumns)
  // console.log('gridApi', gridApi, containerHasContent, gridContainerElement)

  if (gridApi && containerHasContent) {
    // console.log('update the grid')
    gridApi.updateGridOptions({
      rowData: gridRows,
      columnDefs: gridColumns
    })
  } else {
    // console.log('CREATE the grid')
    const gridOptions = {
      pinnedTopRowData: makeRowPinnedTop(gridApi),
      rowData: gridRows,
      columnDefs: gridColumns,
      defaultColDef: {
        sortable: false
        // filter: true,
        // floatingFilter: true
      },
      autoSizeStrategy: {
        type: 'fitCellContents'
      },
      rowSelection: {
        mode: 'singleRow',
        checkboxes: false,
        enableClickSelection: true
      },
      getRowId: (cellParams) => cellParams.data.id,
      getRowHeight: function (params) {
        if (Number.isInteger(params.data.rowHeight)) {
          return params.data.rowHeight
        }
      },
      onFilterChanged: function () {
        console.log('onFilterChanged')
        // gridApi.setGridOption('pinnedTopRowData', makeRowPinnedTop(gridApi))
      },
      onCellClicked: function (cellParams) {
        console.log('onCellClicked', cellParams)
        // context.valueBalance = context.valueBalance === 'balance' ? 'period' : 'balance'
        // updateView()
      },
      context,
      theme: myTheme
    }

    gridApi = createGrid(gridContainerElement, gridOptions)
    console.log('grid created')
    gridApi.setGridOption('pinnedTopRowData', makeRowPinnedTop(gridApi))
  }

  enableValueColumnsResizing(gridApi)

  console.timeEnd('show the grid')

  hideElement('statementLoadingMessage')
  showElement('statementContainer')
}

function enableValueColumnsResizing (gridApi) {
  gridApi.getColumns().forEach(function (column) {
    column.addEventListener('widthChanged', function (eventParams) {
      if (!eventParams.column?.colDef?.context?.isValue) return
      const newWidth = eventParams.column.getActualWidth()

      gridApi.getColumns().forEach(function (column2) {
        if (!column2.colDef.context?.isValue) return
        if (!column2.colId === eventParams.column.colId) return

        gridApi.setColumnWidths([
          {
            key: column2,
            newWidth
          }
        ])
      })
    })
  })
}
