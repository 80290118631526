
import { fillString } from '../../../helpers/templating/string-template.js'
import { callApi } from '../../helpers/fetch.js'
import { accessOrganisation } from './access-organisation.js'
import { prepareChartOfAccountsData } from './chart-of-accounts.js'
import { loadAccountCurrencies } from './currencies.js'
import htmlTemplateNotAuthorised from './not-authorised.html'

const moduleStore = {}
moduleStore.accountData = null
moduleStore.organisations = {}

/**
 *
 * @returns {Object|null}
 */
export function accountData () {
  if (moduleStore.accountData?.isAuthorised === false) {
    return null
  }

  return moduleStore.accountData
}

export function getOrganisationData (orgId) {
  return moduleStore.organisations[orgId]
}

/**
 *
 * @param {*} accountData
 */
export function useAccount (accountData) {
  moduleStore.accountData = accountData

  indexOrganisations(accountData)
  extractOrganisations(accountData)

  if (!moduleStore.accountData) return
  moduleStore.accountData.trackingCategoriesData = moduleStore.trackingCategoriesData
}

/**
 * extract the organisations from the _accountOrganisations
 * store it in moduleStore.accountData.organisations
 * @param {*} accountData
 * @returns
 */
function extractOrganisations (accountData) {
  console.log('extractOrganisations', accountData)

  if (!accountData?._accountOrganisations) return

  console.log('extract')
  moduleStore.accountData.organisations = accountData._accountOrganisations.map(function (oneAccountOrg) {
    const org = Object.assign({}, oneAccountOrg._xeroOrganisation)
    org.xeroNetworkKey = org.details?.aPIKey
    org.legalName = org.details?.legalName
    org.baseCurrency = org.details?.baseCurrency

    return org
  })
}
/**
 *
 * @param {*} accountData
 * @returns
 */
function indexOrganisations (accountData) {
  moduleStore.organisations = {}

  if (!accountData?._accountOrganisations) return

  accountData._accountOrganisations.forEach(function (oneAccountOrg) {
    const orgId = oneAccountOrg._xeroOrganisation._id
    moduleStore.organisations[orgId] = oneAccountOrg._xeroOrganisation

    moduleStore.organisations[orgId].trackingData = getOrgTrackings(orgId)
  })
}

export async function addChartOfAccounts () {
  if (!moduleStore.accountData) return
  if (!moduleStore.accountData._id) return

  moduleStore.accountData.chartOfAccounts = await prepareChartOfAccountsData(moduleStore.accountData._id)
}

export async function addFXrates () {
  if (!moduleStore.accountData) return
  if (!moduleStore.accountData._id) return

  const receivedFXRates = await loadAccountCurrencies(moduleStore.accountData._id)

  moduleStore.accountData.fxRates = receivedFXRates.FXrates
}

/**
 *
 * @param {*} accountId
 * @returns
 */
export async function load (accountId) {
  console.log('load', accountId)
  const apiUrl = '/api/account/:accountId/details'

  const responseData = await callApi(apiUrl, {
    accountId
  })
  // Should the account not be authorised, the response might not include the accountId
  if (!responseData?._id) responseData._id = accountId
  await loadTrackingCategories(accountId)

  useAccount(responseData)
  return responseData
}

/**
 *
 * @param {*} accountId
 */
async function loadTrackingCategories (accountId) {
  const apiUrl = '/api/xero/a/:accountId/trackings'

  const responseData = await callApi(apiUrl, {
    accountId
  })
  moduleStore.trackingCategoriesData = responseData.trackingData
}

/**
 *
 * @param {*} orgId
 * @returns
 */
function getOrgTrackings (orgId) {
  const orgTrackings = moduleStore.trackingCategoriesData?.find(function (trackingCategoryData) {
    return trackingCategoryData.orgId === orgId
  })
  return orgTrackings
}

export function showUnauthorisedAccount (params) {
  const contentElement = document.getElementById('app-content')

  const message = params?.isUserLoggedIn ? 'sign in another account' : 'sign in your account'

  const html = fillString(htmlTemplateNotAuthorised, { message })
  contentElement.innerHTML = html
}

export const appAccount = {
  accountData,
  getOrganisationData,
  useAccount,
  addChartOfAccounts,
  addFXrates,
  load,
  showUnauthorisedAccount,
  accessOrganisation: function (targetString) {
    return accessOrganisation(moduleStore.accountData.organisations, targetString)
  }
}
