import { group } from 'd3'
import { combineGroup } from '../../../../helpers/arrays/combine-group.js'

export function makeRows (data) {
  console.log('makeRows', data)

  const tbValues = data?.tbValues?.trialBalances
  const journalsValues = data?.journalsValues?.monthlyValues
  const ledgersValues = data?.ledgersValues?.monthlyValues
  const adjustments = data?.adjustments
  console.log('journalsValues', journalsValues)

  // Map the chart of accounts
  const allChartOfAccounts = [...data?.journalsValues?.chartOfAccounts, ...data?.tbValues?.chartOfAccounts, ...data?.ledgersValues?.chartOfAccounts]
  const chartOfAccountsMap = group(allChartOfAccounts,
    function (d) { return d.id }
  )
  console.log('chartOfAccountsMap', chartOfAccountsMap)

  // Combine the TB and Journals values into a single map
  const combinedValues = combineGroup(
    [
      {
        values: journalsValues,
        output: 'journalsValues'
      },
      {
        values: tbValues,
        output: 'tbValues'
      },
      {
        values: ledgersValues,
        output: 'ledgersValues'
      },
      {
        values: adjustments,
        output: 'adjustments'
      }
    ],
    function (d) {
      const monthYYYYMM = extractMonth(d)
      return [d.accounting_chart_of_account_id, monthYYYYMM].join('::')
    },
    function (d, mapEntry) {
      // The month
      mapEntry.month = extractMonth(d)

      // The chart of account

      // If the chart of account is already set, skip
      if (mapEntry.chartOfAccount) return

      if (!d.accounting_chart_of_account_id) return

      // Include the actual chart of account object
      const chartOfAccount = chartOfAccountsMap.get(d.accounting_chart_of_account_id)
      if (chartOfAccount) {
        mapEntry.chartOfAccount = chartOfAccount[0]
      } else {
        mapEntry.chartOfAccount = d.accounting_chart_of_account_id
      }
    }
  )
  console.log('combinedValues', combinedValues)

  const rows = Array.from(combinedValues.values())
  console.log('rows', rows)
  return rows
}

function extractMonth (d) {
  const monthYYYYMM = d.month?.substring(0, 7) || d.date?.substring(0, 7)
  return monthYYYYMM
}
