import { ascending, rollup } from 'd3'

/**
*
* @param {*} rates [{from<XXX>, to<XXX>, rate, date<YYYY-MM-DD>}]
*/
export function mapRates (rates) {
  const map = rollup(rates,
    groupRates => groupRates.sort((r1, r2) => ascending(r1.date, r2.date)),
    rate => rate.from,
    rate => rate.to)
  return map
}
