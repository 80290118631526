
/*
type, // if 'break': show empty cells; and rowHeight reduced
*/

import { descending, sum } from 'd3'
import { convertToFullLayout } from '../../../../../server/financials-pg/financials-statements/layouts/convert-to-full-layout.js'
import { computeCell } from './compute-cell.js'

export function byLayout (context, columns) {
  // console.log('byLayout', context, columns)

  const gridRows = []

  let breakRowId = 0

  const layoutSettingsProperty = context.statementType === 'pnl' ? 'layoutSettingsAutoPnl' : 'layoutSettingsAutoBs'
  const layoutSettings = context.statementLayouts?.[layoutSettingsProperty]
  const layoutFullSettings = convertToFullLayout(layoutSettings, context.pgChartOfAccounts.chartOfAccounts)

  context.computedCell = 0

  layoutFullSettings?.rows.forEach(function (row) {
    const rowData = {
      id: row.id,
      label: row.label,
      level: 0,

      type: row.type,
      children: row.children,

      viewMultiplier: row.viewMultiplier,
      formulaAlaSQL: row.formulaAlaSQL,
      formulaAlaSQLOperator: row.formulaAlaSQLOperator,
      formatting: row.formatting
    }

    if (row.type === 'break') {
      breakRowId++
      rowData.id = `break-${breakRowId}`
      rowData.rowHeight = 10
    }

    const showChildren = context.grid.openedGroups.has(rowData.id)
    const childrenRows = []

    if (row.children) {
      // We want to compute the children in any case; as while a group might some to 0, it might have values which offset each others

      rowData.childrenRows = []

      row.children.forEach(function (child) {
        // const targetChartOfAccounts = child?.context?.chartOfAccounts
        // const targetChartOfAccountsIds = targetChartOfAccounts?.map(d => d.id)

        // Find the values matching: Not used actually
        // const filteredValues = valuesArray?.filter(function (d) {
        //   if (!targetChartOfAccountsIds.includes(d.accounting_chart_of_account_id)) return false

        //   return true
        // })

        const childRow = {
          id: child.id,
          label: child.label,
          level: 1,

          viewMultiplier: row.viewMultiplier,
          formatting: row.formatting,

          context: child?.context

          // display: true

          // filtered values:
          // values: filteredValues,
          // fxConversion: targetChartOfAccounts[0]?.settings?.fxConversion
        }

        computeRowValues(childRow, columns, context)

        childRow.shouldShow = shouldShowRow(childRow)
        rowData.childrenRows.push(childRow)

        if (childRow.shouldShow) {
          childrenRows.push(childRow)
        }
      })
    }

    computeRowValues(rowData, columns, context)
    rowData.shouldShow = shouldShowRow(rowData)
    if (rowData.shouldShow) {
      gridRows.push(rowData)
    }

    if (showChildren) {
      childrenRows.sort(function (row1, row2) {
        const value1 = (context.valueBalance === 'balance') ? row1.values[row1.values.length - 1] : sum(row1.values)
        const value2 = (context.valueBalance === 'balance') ? row2.values[row2.values.length - 1] : sum(row2.values)

        const sortMultipler = row.expectedSign * row.viewMultiplier || 1
        return descending(sortMultipler * value1, sortMultipler * value2)
      })

      gridRows.push(...childrenRows)
    }
  })

  console.log('computedCell', context.computedCell)
  return gridRows
}

function shouldShowRow (row) {
  if (row.type === 'break') return true
  if (row.values.some(d => (d !== null && d !== undefined && d !== 0))) return true
  if (row.childrenRows && row.childrenRows.some(d => d.shouldShow)) return true

  return false
}

function computeRowValues (row, columns, context) {
  // console.log('computeRowValues', row, columns, context)
  row.values = []

  goThroughColumns(row, columns, context)
}

function goThroughColumns (row, columns, context) {
  columns.forEach(function (column) {
    if (column.children) goThroughColumns(row, column.children, context)

    if (!column.context?.isValue) return

    const cellValue = computeCell({
      data: row,
      colDef: column,
      context
    })
    context.computedCell++

    row.values.push(cellValue)
  })
}
