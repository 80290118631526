import { setElementHtml } from '../../../helpers/dom.js'
import { callApi } from '../../../helpers/fetch.js'

export async function showJournalsIssues (orgId) {
  console.log('showJournalsIssues show()', orgId)

  const apiResponse = await loadJournalsIssues(orgId)
  console.log('apiResponse', apiResponse)

  const infoOnJournalsWithNoLines = makeInfoOnJournalsWithNoLines(apiResponse?.analysisJournalsNoLines)
  setElementHtml('analysisJournalsNoLines', infoOnJournalsWithNoLines)

  const missingNumbers = apiResponse?.missingJournals?.length === 0 ? '' : apiResponse?.missingJournals?.map(oneJournal => oneJournal.number).join(', ')
  const missingJournals = `${apiResponse?.missingJournals?.length} ${missingNumbers}`
  setElementHtml('missingJournals', missingJournals)
}

export async function loadJournalsIssues (orgId) {
  const url = `/api/data-review/journals-issues/${orgId}`
  const apiResponse = await callApi(url)
  return apiResponse
}

function makeInfoOnJournalsWithNoLines (analysisJournalsNoLines) {
  console.log('makeInfoOnJournalsWithNoLines', analysisJournalsNoLines)
  if (!analysisJournalsNoLines) return '!! No analysis available'
  if (+analysisJournalsNoLines.count === 0) return 0

  const numberJournalsNoLine = analysisJournalsNoLines.count

  return `${numberJournalsNoLine}: Between ${analysisJournalsNoLines.min_number} [${analysisJournalsNoLines.min_date}] and ${analysisJournalsNoLines.max_number} [${analysisJournalsNoLines.max_date}]`
}
