import alasql from 'alasql'
import { alaSQLtableName } from '../alasql.js'

export function computeConsolidation (context) {
  // #3 Do we need to compute consolidation?
  if (context.includedEntityIds.length === 1) return

  console.log('computeConsolidation', context)

  // #1 Remove consolidation data
  alasql(`DELETE FROM ${alaSQLtableName} WHERE accounting_organisation_id = '${consolidationOrg.id}'`)
  console.log('deleted consolidation data')

  // const organisationIds = valuesDB.map(value => value.accounting_organisation_id)
  // const uniqueOrganisationIdsSet = new Set(organisationIds)

  // if (uniqueOrganisationIdsSet.size === 1) return

  // #4 Select the data for the consolidation

  // Select data to manipulate
  // #2 Debug: what's in the DB
  // const valuesDB = selectAllalaSQL()
  // console.log('computeConsolidation', valuesDB)

  const quotedIncludedEntityIds = context.includedEntityIds.map(d => `'${d}'`).join(',')
  const valuesDB = alasql(`
    SELECT * 
    FROM ${alaSQLtableName} 
    WHERE accounting_organisation_id IN (${quotedIncludedEntityIds})
  `)
  console.log('computeConsolidation', valuesDB)

  // #5 Insert consolidation data
  console.time('insertConsolidationData')

  // const valuesToInsert = []
  valuesDB.forEach(function (value) {
    const newValue = Object.assign({}, value)
    newValue.accounting_organisation_id = consolidationOrg.id
    newValue.entity = consolidationOrg
    // valuesToInsert.push(newValue)

    alasql(`INSERT INTO ${alaSQLtableName} VALUES ?`, [newValue])
  })
  // alasql(`INSERT INTO ${alaSQLtableName} SELECT * FROM ?`, [valuesToInsert])
  // March 2nd 2025: seems no difference in performance between individual or batch insert
  console.timeEnd('insertConsolidationData')
}

export const consolidationOrg = {
  base_currency: 'XXX',
  id: 'consolidationOrg',
  name: 'Consolidation',
  is_zenflow: true
}
