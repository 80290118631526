import { callApi } from '../../helpers/fetch.js'
import { appAccount } from '../../modules/user/account.js'

export function addListeners (gridApi, containerElement, context) {
  containerElement.addEventListener('click', async (event) => {
    console.log('click', event)
    const target = event.target.closest('[data-action="refresh-asap"]')
    if (!target) return

    const entityId = target.getAttribute('data-entity-id')
    console.log('refresh asap', entityId)
    await callSchedule(gridApi, entityId, context)
  })
}

async function callSchedule (gridApi, entityId, context) {
  const gridRowNode = gridApi.getRowNode(entityId)

  gridRowNode.data.isLoading = true
  gridApi.refreshCells({
    rowNodes: [gridRowNode],
    // columns: [cellParams.colDef],
    force: true
  })

  const pgOrg = context.accountingOrganisations.find(a => a.id === entityId)

  const mongoOrgs = appAccount.accountData()?._accountOrganisations
  const targetMongoOrg = mongoOrgs.find(function (m) {
    return m._xeroOrganisation.orgXeroId === pgOrg.xero_id
  })

  const orgId = targetMongoOrg._xeroOrganisation._id
  // const orgId = 'pg-' + entityId // PG.. but that breaks the authorisation

  const apiResponse = await callApi('/api/sync-pg/schedule/:orgId', {
    orgId
  }, { method: 'POST' })

  if (apiResponse.scheduleResponse) {
    gridRowNode.data.syncStats.nextScheduled = apiResponse.scheduleResponse
  }

  gridRowNode.data.isLoading = false
  gridApi.refreshCells({
    rowNodes: [gridRowNode],
    force: true
  })
}
