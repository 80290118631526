
// App config
import { fillString } from '../../../../helpers/templating/string-template.js'
import * as configVar from '../../../config/config.js'
import { showElement } from '../../../helpers/dom.js'
import { makeXeroLink } from '../../../helpers/xero-links.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'
import { infosShowAll } from './infos-show-all.js'

// Page Module
import htmlIndex from './infos.html'
import { showOrgAccounts } from './org-accounts/show-org-accounts.js'
import { loadOrgUsers } from './org-users/load-org-users.js'
import { groupUsersAndAccounts } from './org-users/prepare-data.js'
import { showOrgUsers } from './org-users/show-org-users.js'
import { showSyncManagement } from './sync-management/show-sync-management.js'
import { showOrgTokens } from './tokens/show-tokens.js'

/**
 *
 * @returns
 */
export async function showOrgInfos (orgId) {
  console.log('showOrgInfos show()', orgId)

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  showOrgTokens(orgId)

  // All Data info
  infosShowAll(orgId)

  showSyncManagement(orgId)

  const organisationAccess = await loadOrgUsers(orgId)
  showInfos(organisationAccess)

  linkToPostgresAdmin(contentElement, orgId)
}

async function showInfos (organisationAccess) {
  const containerElement = document.getElementById('orgInfos')
  // containerElement.innerText = 'ok'

  showOrgDetails(organisationAccess)

  const byUsersAndAccounts = groupUsersAndAccounts(organisationAccess)
  showOrgAccounts(byUsersAndAccounts)
  showOrgUsers(byUsersAndAccounts)

  // showDataInfo(organisationAccess.journalsInfo)

  // Hide Loading
  const loadingElement = containerElement.querySelector('progress')
  loadingElement.classList.add('is-hidden')
}

function showOrgDetails (organisationAccess) {
  console.log('showOrgDetails', organisationAccess)

  const containerElement = showElement('organisationOverallDetails')
  showElement('organisationSyncManagement')

  const targetElements = containerElement.querySelectorAll('[d-data="organisationName"]')
  targetElements.forEach(function (oneElement) {
    oneElement.innerText = organisationAccess.organisation.name
  })

  const targetElements2 = containerElement.querySelectorAll('[d-data="organisationShortcode"]')
  targetElements2.forEach(function (oneElement) {
    oneElement.innerText = organisationAccess.organisation.xeroShortcode
  })

  const targetElementsLinksToXero = containerElement.querySelectorAll('[d-data="organisationLinkToXero"]')
  const linkToXero = makeXeroLink('Home', {
    shortCode: organisationAccess.organisation.xeroShortcode
  })
  targetElementsLinksToXero.forEach(function (oneElement) {
    oneElement.innerHTML = fillString('<a href=":linkToXero" target="_blank">Open in Xero</a>', {
      linkToXero
    })
  })

  //
  const targetElementsLinksToAdminChartAccounts = containerElement.querySelectorAll('[d-data="orgAdminChartAccounts"]')
  const urlToOrgChartAccounts = fillString('/app/admin/org-info/:orgId', {
    orgId: organisationAccess.organisation.id
  })
  const linkToChartAccounts = fillString('<a href=":urlToOrgChartAccounts">Chart of Accounts</a>', {
    urlToOrgChartAccounts
  })
  targetElementsLinksToAdminChartAccounts.forEach(function (oneElement) {
    oneElement.innerHTML = linkToChartAccounts
  })

  //
  const targetElementsLinkToOrgSyncs = containerElement.querySelectorAll('[d-data="orgAdminSyncs"]')
  const urlToOrgSyncs = fillString('/app/admin/syncs/:orgId', {
    orgId: organisationAccess.organisation.id
  })
  const linkToOrgSync = fillString('<a href=":urlToOrgSyncs">View Syncs</a>', {
    urlToOrgSyncs
  })
  targetElementsLinkToOrgSyncs.forEach(function (oneElement) {
    oneElement.innerHTML = linkToOrgSync
    // oneElement.innerT = linkToOrgSync
  })
}

function linkToPostgresAdmin (containerElement, orgId) {
  const url = `/app/admin/org-pg-admin/${orgId}`
  console.log('linkToPostgresAdmin >> url', url)

  const targetElementsLinkToPostgresAdmin = containerElement.querySelectorAll('[d-data="orgPostgresAdminPage"]')
  console.log('targetElementsLinkToPostgresAdmin', targetElementsLinkToPostgresAdmin)
  targetElementsLinkToPostgresAdmin.forEach(function (oneElement) {
    const result = `<a href="${url}">Postgres Admin</a>`
    console.log('result', result)
    oneElement.innerHTML = result
  })
}
