import { roundNumber } from '../../../../../helpers/numbers/round-number.js'
import { computeAccountsJS } from './compute-cell-js.js'
import { computeAccountsAlaSQL } from './compute-cell-alasql.js'
import { computeAlaSQLFormula } from './compute-alasql-formula.js'
import { computeFromGroupedMap } from './compute-from-map.js'

// const METHOD = 'alaSQL'
const METHOD = 'groupedMap'

export function computeCell (cellParams) {
  // console.log('computeCell', cellParams)

  if (cellParams.data.type === 'break') return null

  // if (cellParams.data.children) return null

  let computedValue = null

  if (cellParams.data.formulaAlaSQL) {
    computedValue = computeAlaSQLFormula(cellParams)
  } else {
    if (METHOD === 'alaSQL') {
      computedValue = computeAccountsAlaSQL(cellParams)
    } else if (METHOD === 'groupedMap') {
      // JS method using indexed map is very fast; and scalable
      computedValue = computeFromGroupedMap(cellParams)
    } else {
      // JS method a bit faster actually; but less scalable
      computedValue = computeAccountsJS(cellParams)
    }
  }

  computedValue = roundNumber(computedValue, 4)
  computedValue *= cellParams.data?.viewMultiplier || 1
  // console.log('computedValue', cellParams.colDef.context?.month, cellParams.data.id, cellParams.data?.viewMultiplier, computedValue)

  return computedValue
}
