import { setElementHtml } from '../../../helpers/dom.js'

// Module
import htmlTemplateSelectorOthers from './options-others.html'
import htmlTemplateButton from './button-template.html'
import { optionSelectorData } from '../data.js'
import { executeOnChange } from '../onchange.js'
import { fillString } from '../../../../helpers/templating/string-template.js'

export function activateOtherOptions (params) {
  console.log('activateOtherOptions', params)

  const htmlSelector = htmlTemplateSelectorOthers

  const dom = setElementHtml('optionSelectorContent', htmlSelector)
  if (!dom) return

  activateOption(
    [{
      label: 'Left to Right',
      choice: 'ascending'
    }, {
      label: 'Right to Left',
      choice: 'descending'
    }],
    'timeDirectionButtons',
    'timeDirection',
    function (choice) { return choice },
    params
  )

  activateOption(
    [
      {
        label: 'Consolidated Number',
        choice: 'consolidated'
      },
      {
        label: 'Detailed: Date > Entity',
        choice: 'detailedDateEntity'
      },
      {
        label: 'Detailed: Entity > Date',
        choice: 'detailedEntityDate'
      }
    ],
    'multiEntityLayoutButtons',
    'entitiesLayout',
    function (choice) { return choice },
    params
  )

  activateOption(
    [{
      label: '1,000',
      choice: 0
    }, {
      label: '1,000.00',
      choice: 2
    }],
    'decimalsButtons',
    'decimals',
    function (choice) { return +choice },
    params
  )
}

function activateOption (options, buttonContainerElementId, optionProperty, choiceFunction, params) {
  const targetAttribute = ['d-', optionProperty].join('')
  showButtons(
    options,
    buttonContainerElementId,
    htmlTemplateButton,
    targetAttribute
  )

  buttonsListener(buttonContainerElementId, targetAttribute, optionSelectorData, optionProperty, choiceFunction, params)
}

function buttonsListener (selectorElementId, targetAttribute, optionSelectorData, optionProperty, choiceFunction, params) {
  const selectorElement = document.getElementById(selectorElementId)
  selectorElement.addEventListener('click', function (clickEvent) {
    const target = clickEvent.target.closest('button')
    if (!target) return
    console.log('target', target, targetAttribute)

    const targetAttributeValue = target.getAttribute(targetAttribute)

    console.log('listener', optionSelectorData, 'optionProperty', optionProperty, 'targetAttributeValue', targetAttributeValue, optionSelectorData[optionProperty])
    optionSelectorData[optionProperty] = choiceFunction(targetAttributeValue)
    console.log('registered', optionSelectorData, 'optionProperty', optionProperty, 'targetAttributeValue', targetAttributeValue, optionSelectorData[optionProperty])
    executeOnChange(params)
  })
}

function showButtons (options, buttonContainerElementId, buttonTemplateHtml, targetAttribute) {
  const buttonsHtml = []
  options.forEach(function (oneOption) {
    const htmlButton = fillString(buttonTemplateHtml, {
      attributeValue: oneOption.choice,
      attributeName: targetAttribute,
      label: oneOption.label
    })

    buttonsHtml.push(htmlButton)
  })

  // console.log('buttonsHtml', buttonsHtml)
  setElementHtml(buttonContainerElementId, buttonsHtml.join(''))
}
