import { callApi } from '../../../helpers/fetch.js'

/**
 * @param {*} context
 * @param {String} context.showOrgId
 * @returns
 */
export async function loadData (context) {
  console.log('loadData', context)
  const orgId = context.showOrgId

  //
  const journalsValuesCall = callApi('/api/finlogic/data/:orgId/pg-monthly-values/:monthFrom/:monthTo', { orgId })
  const tbValuesCall = callApi('/api/finlogic/data/:orgId/pg-trial-balances-values/:monthFrom/:monthTo', { orgId })
  const ledgersValuesCall = callApi('/api/finlogic/data/:orgId/pg-ledgers-values/:monthFrom/:monthTo', { orgId })

  const adjustmentsNeedCall = callApi('/api/finlogic/data/:orgId/pg-monthly-values-adjustments-need', { orgId })

  const [journalsValues, tbValues, ledgersValues, adjustments] = await Promise.all([
    journalsValuesCall,
    tbValuesCall,
    ledgersValuesCall,
    adjustmentsNeedCall
  ])

  context.data = {
    journalsValues: journalsValues?.monthlyValues,
    tbValues,
    ledgersValues,
    adjustments
  }
}
