
const VALUE_PREFIX_DEFAULT = ':'

/**
 * !! JS has Template Literals https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals which does the work well
 * Will replace :values from the string
 * @param {String} stringPattern
 * @param {Object} values Object {param:value}
 * @param {Object} [options]
 * @param {String} [options.valuePrefix]
//  * @param {Boolean} [options.skipUndefined] Not implemented
 * @returns
 */
export function fillString (stringPattern, values, options) {
  let _stringPattern = stringPattern

  const valuePrefix = typeof options?.valuePrefix === 'string' ? options?.valuePrefix : VALUE_PREFIX_DEFAULT

  if (!values) return stringPattern

  Object.keys(values)?.forEach(function (oneParam) {
    const paramTarget = valuePrefix + oneParam

    _stringPattern = _stringPattern.replaceAll(paramTarget, values[oneParam])
  })
  return _stringPattern
}

/**
 * Useful to apply css class is-hidden for eg
 * @param {*} check
 * @param {String} ifTrue  eg. is-hidden
 * @param {String} [ifFalse] Defaults to ''
 * @returns
 */
export function conditionalValue (check, ifTrue, ifFalse) {
  return check ? ifTrue : ifFalse || ''
}
