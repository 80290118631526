import { callApi } from '../helpers/fetch.js'

//
export async function loadCurrenciesForAccount (mongoAccountId) {
  const apiUrl = '/currencies/view/for-account/:mongoAccountId'
  const data = await callApi(apiUrl, {
    mongoAccountId
  })
  // console.log('loadCurrenciesForAccount', data)

  return data
}
