import { ascending, rollup } from 'd3'

/**
 * 1 Row per entity
 * @param {*} entitiesResponse
 * @returns
 */
export function makeRows (trackingsResponse, entitiesResponse) {
  if (!entitiesResponse) return []
  if (!Array.isArray(entitiesResponse)) return []
  if (entitiesResponse.length === 0) return []

  const trackingsMap = rollup(trackingsResponse,
    (values) => {
      return {
        categoryOptions: values,
        category: {
          id: values[0].category_id,
          name: values[0].category_name,
          status: values[0].category_status
        },
        accountingOrganisation: {
          id: values[0].accounting_organisation_id
        }
      }
    },
    (d) => d.accounting_organisation_id,
    (d) => d.category_name
  )
  console.log('rollup', trackingsMap)

  entitiesResponse.forEach(entity => {
    entity.entityTrackingsMap = trackingsMap.get(entity.id)
  })

  entitiesResponse.sort(function (a, b) {
    return ascending(a.name, b.name)
  })

  return entitiesResponse
}
