import { setElementHtml } from '../../../helpers/dom.js'
import { callApi } from '../../../helpers/fetch.js'

export async function showEntityRecordsCount (orgId) {
  console.log('showEntityRecordsCount show()', orgId)

  const apiResponse = await getCountEntityRecords(orgId)
  console.log('apiResponse', apiResponse)

  const htmlStringElements = []

  Object.keys(apiResponse).forEach(function (oneKey) {
    htmlStringElements.push(`<b>${oneKey}</b>: ${apiResponse[oneKey]}`)
  })

  setElementHtml('postgresDataCounts', htmlStringElements.join('<br>'))
}

export async function getCountEntityRecords (orgId) {
  const url = `/api/data-review/count-entity-records/${orgId}`
  const results = await callApi(url)
  return results
}
