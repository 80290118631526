import { setElementHtml } from '../../../helpers/dom.js'
import { callApi } from '../../../helpers/fetch.js'

export async function showDataCoverage (orgId) {
  console.log('showDataCoverage show()', orgId)

  const apiResponse = await loadDataCoverage(orgId)
  console.log('apiResponse', apiResponse)

  const template = `
    Journals Dates: from ${apiResponse?.journals_min_date} to ${apiResponse.journals_max_date}
    <br>Journals Numbers: from ${apiResponse?.journals_min_number} to ${apiResponse?.journals_max_number}
    <br>Trial Balances Dates: from ${apiResponse?.trial_balances_min_date} to ${apiResponse?.trial_balances_max_date}
    <br>Sources Dates: from ${apiResponse?.sources_min_date} to ${apiResponse?.sources_max_date}
    <br>Ledgers Dates: from ${apiResponse?.ledgers_min_date} to ${apiResponse?.ledgers_max_date}
  `
  setElementHtml('postgresDataCoverageInfos', template)
}

export async function loadDataCoverage (orgId) {
  const url = `/api/data-review/data-coverage/${orgId}`
  const apiResponse = await callApi(url)
  return apiResponse
}
