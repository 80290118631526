import { formatMemoryUsage } from '../../../../../helpers/helpers/format-memory-size.js'
import { generateAdditionalColumnDefs } from '../helpers/include-additional-columns.js'

export function makeColumnDefs (modulesData) {
  // console.log('makeColumnDefs', modulesData)

  const columnDefs = []

  columnDefs.push({
    field: 'module',
    pinned: 'left'
  })
  columnDefs.push({
    field: 'step',
    pinned: 'left'
  })
  columnDefs.push({
    field: 'timestamp',
    pinned: 'left'
  })
  columnDefs.push({
    field: 'stepTime',
    pinned: 'left',
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Heap Total',
    valueGetter: function (cellParams) {
      const heapTotal = formatMemoryUsage(cellParams.data.memoryUsage?.heapTotal)
      return heapTotal
    }
  })
  columnDefs.push({
    headerName: 'Heap Used',
    valueGetter: function (cellParams) {
      const heapUsed = formatMemoryUsage(cellParams.data.memoryUsage?.heapUsed)
      return heapUsed
    }
  })
  columnDefs.push({
    headerName: 'Heap Limit',
    valueGetter: function (cellParams) {
      const heapLimit = formatMemoryUsage(cellParams.data.memoryUsage?.heapLimit)
      return heapLimit
    }
  })

  columnDefs.push({
    field: 'loopCounter',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'page',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'offset',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'ifModifiedSince'
  })
  columnDefs.push({
    field: 'numberReceived',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'numberFound',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'numberUpdated',
    type: 'numericColumn'
  })

  // Other properties
  const baseProperties = [
    'module',
    'step',
    'timestamp',
    'loopCounter',
    'page',
    'offset',
    'ifModifiedSince',
    'numberReceived',
    'numberFound',
    'numberUpdated',

    // Added properties
    'timestampEnd',
    'stepTime'
  ]

  const additionalColumnDefs = generateAdditionalColumnDefs(modulesData, baseProperties)
  columnDefs.push(...additionalColumnDefs)

  return columnDefs
}
