import * as d3 from 'd3'
import { makeKeys } from './make-keys.js'

export function d3Group (data, groupingParams, monthsArray, context) {
  console.log('d3Group', data, groupingParams, monthsArray, context)
  if (!data) return

  const d3GroupingFunctions = []
  groupingParams.groupBy.forEach(function (oneGroupBy) {
    const groupingFunction = function (oneItem) {
      const entryKeys = makeKeys(oneItem)
      const itemTargetKey = entryKeys[oneGroupBy]
      return itemTargetKey
    }
    d3GroupingFunctions.push(groupingFunction)
  })
  // console.log('d3GroupingFunctions', d3GroupingFunctions)

  return d3.rollup(data,
    function (groupItems) {
      return rollupGroupItems(groupItems, monthsArray, context)
    },
    ...d3GroupingFunctions)
}

function rollupGroupItems (groupItems, monthsArray, context) {
  console.log('groupItems', groupItems)

  const rollupObject = {
    items: groupItems,
    //
    // Values: group by month
    monthly: d3.rollup(groupItems,
      function (groupMonthlyItems) {
        return {
          count: d3.count(groupMonthlyItems, function (item) {
            return valueOfItem(item, context)
          }),
          value: d3.sum(groupMonthlyItems, function (item) {
            return valueOfItem(item, context)
          }),
          items: groupMonthlyItems
        }
      },
      function (item) {
        return item.month
      }
    ),

    totalValue: d3.sum(groupItems, function (item) {
      return valueOfItem(item, context)
    }),
    values: Array.from(monthsArray, () => null)
  }
  // Compute the values for the monthly array
  groupItems.forEach(function (item) {
    const monthIndex = monthsArray.indexOf(item.month)
    rollupObject.values[monthIndex] = valueOfItem(item, context)
  })

  // Compute monthly info
  rollupObject.numberNonZeroValues = rollupObject.values.filter(function (monthValue) {
    return Number.isFinite(monthValue) && monthValue !== 0
  }).length

  // Changes in value
  rollupObject.changeValue = []
  rollupObject.changePct = []
  rollupObject.values.forEach(function (value, index) {
    const previousValue = rollupObject.values[index - 1]

    if (index === 0) {
      rollupObject.changeValue.push(null)
      rollupObject.changePct.push(null)
      return
    }

    const changeValue = value - previousValue
    const changePct = changeValue / previousValue

    rollupObject.changeValue.push(changeValue)
    rollupObject.changePct.push(changePct)
  })

  // Grouping keys
  const groupingKeys = {
    contacts: new Map(),
    chartOfAccounts: new Map(),
    entities: new Map()
  }
  groupItems.forEach(function (oneItem) {
    const entryKeys = makeKeys(oneItem)

    //
    if (!groupingKeys.contacts.has(entryKeys.contactKey)) {
      groupingKeys.contacts.set(entryKeys.contactKey, [])
    }
    if (!oneItem.contact) {
      groupingKeys.contacts.get(entryKeys.contactKey).push({
        name: entryKeys.contactKey,
        isMissing: true
      })
    } else {
      groupingKeys.contacts.get(entryKeys.contactKey).push(oneItem.contact)
    }

    //
    if (!groupingKeys.chartOfAccounts.has(entryKeys.accountKey)) {
      groupingKeys.chartOfAccounts.set(entryKeys.accountKey, [])
    }
    groupingKeys.chartOfAccounts.get(entryKeys.accountKey).push(oneItem.chartOfAccount)

    //
    if (!groupingKeys.entities.has(entryKeys.entityKey)) {
      groupingKeys.entities.set(entryKeys.entityKey, [])
    }
    groupingKeys.entities.get(entryKeys.entityKey).push(oneItem.entity)
  })
  rollupObject.contacts = groupingKeys.contacts
  rollupObject.chartOfAccounts = groupingKeys.chartOfAccounts
  rollupObject.entities = groupingKeys.entities

  return rollupObject
}

function valueOfItem (item, context) {
  // console.log('valueOfItem', item, context)
  const valueCurrency = item.currencySymbol
  const targetCurrency = context.currencySymbol
  const needFxConversion = !((valueCurrency === targetCurrency || targetCurrency === 'XXX'))

  const localAmount = parseFloat(item.total_amount)
  // console.log('localAmount', localAmount, needFxConversion)

  if (!needFxConversion) {
    return localAmount
  }

  const fxPairCode = `${valueCurrency}.${targetCurrency}`
  const fxPairRates = context.accountData.fxRates[fxPairCode]
  console.log('fxPairRates', fxPairRates)
  const fxRateItem = fxPairRates.find(function (fxRateItem) {
    return fxRateItem.month === item.month
  })

  const fxRate = fxRateItem?.rate || 1
  return localAmount * fxRate
}
