import { group } from 'd3'
import { selectAllalaSQL } from './alasql.js'
// import { generateRandomString } from '../../../../helpers/helpers/random.js'

export const zenDb = {}

export function indexValuesDB () {
  const allStoredValues = selectAllalaSQL()

  zenDb.map = group(allStoredValues,
    d => d.accounting_organisation_id,
    d => d.currencyCode,
    d => d.accounting_chart_of_account_id,
    d => d.month
  )
  // selectAllalaSQL()
}
