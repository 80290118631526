import dayjs from 'dayjs'
import { formatNumber } from '../../../../helpers/helpers/numbers.js'

export function makeColumnDefs (context) {
  const columnDefs = []

  // Entity column, only when needed
  const entityColumn = {
    headerName: 'Entity',
    valueGetter: function (cellParams) {
      return cellParams.data.entity?.name
    },
    pinned: 'left'
  }
  if (Array.isArray(context.showOrgId)) {
    entityColumn.hide = false
  } else {
    entityColumn.hide = true
  }
  columnDefs.push(entityColumn)

  columnDefs.push({
    headerName: 'Statement',
    valueGetter: function (cellParams) {
      const chartOfAccountId = cellParams.data.accounting_chart_of_account_id
      const chartOfAccount = getChartOfAccount(context, chartOfAccountId)
      return chartOfAccount?.statement
    },
    // width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Type',
    valueGetter: function (cellParams) {
      const chartOfAccountId = cellParams.data.accounting_chart_of_account_id
      const chartOfAccount = getChartOfAccount(context, chartOfAccountId)
      return chartOfAccount?.xero_type
    },
    // width: 100,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'AccountId',
    valueGetter: function (cellParams) {
      const chartOfAccountId = cellParams.data.accounting_chart_of_account_id
      return chartOfAccountId
    },
    // width: 120,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Code',
    valueGetter: function (cellParams) {
      const chartOfAccountId = cellParams.data.accounting_chart_of_account_id
      const chartOfAccount = getChartOfAccount(context, chartOfAccountId)
      return chartOfAccount?.code
    },
    // width: 80,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Name',
    valueGetter: function (cellParams) {
      const chartOfAccountId = cellParams.data.accounting_chart_of_account_id
      const chartOfAccount = getChartOfAccount(context, chartOfAccountId)
      return chartOfAccount?.name
    },
    // width: 200,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Month',
    valueGetter: function (cellParams) {
      return dayjs(cellParams.data.month).format('YYYY-MM')
    },
    // width: 200,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Amount (Credit positive)',
    valueGetter: function (cellParams) {
      return -1 * (cellParams.data.sum || 0)
    },
    valueFormatter: function (cellParams) {
      return formatNumber(cellParams.value, context.viewDecimals)
    },
    type: 'numericColumn'
    // width: 200
  })

  columnDefs.push({
    headerName: 'Currency',
    valueGetter: function (cellParams) {
      return cellParams.data.currency
    }
    // width: 80
  })

  columnDefs.push({
    headerName: 'Tracking1',
    valueGetter: function (cellParams) {
      const trackingOptionId = cellParams.data.t1
      return showTracking(context, trackingOptionId)
    }
    // width: 80
  })

  columnDefs.push({
    headerName: 'Tracking2',
    valueGetter: function (cellParams) {
      const trackingOptionId = cellParams.data.t2
      return showTracking(context, trackingOptionId)
    }
    // width: 80
  })

  return columnDefs
}

function getChartOfAccount (context, chartOfAccountId) {
  return context.data?.chartOfAccounts?.find(c => c.id === chartOfAccountId)
}

function showTracking (context, trackingOptionId) {
  const tracking = getTracking(context, trackingOptionId)
  if (!tracking) return
  return [tracking?.name, tracking?.['TrackingOptions.name']].join('=')
}

function getTracking (context, trackingOptionId) {
  return context.data?.trackings?.find(function (t) {
    return t['TrackingOptions.id'] === trackingOptionId
  })
}
