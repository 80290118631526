import { rollup, sum } from 'd3'

export function computeIntercoTotals (groupTransactions) {
  // Compute intercoTransaction totals: allowing to identify the issues
  const groupingFunctions = [
    function (transaction) {
      return transaction.intercoTransactionId
    },
    function (transaction) {
      return transaction.recordingEntity?._id
    }
  ]

  const rollupFunction = function (transactions) {
    return {
      sum: sum(transactions, function (transaction) {
        return transaction.value
      }),
      debits: sum(transactions, function (transaction) {
        // Only return 1 side of the transaction value (because all transactions sum to 0)
        if (Math.sign(transaction.value) === -1) return 0
        return transaction.value
      }),
      count: transactions.length,
      transactions
    }
  }

  const intercoTransactionTotals = rollup(groupTransactions, rollupFunction, ...groupingFunctions)
  // console.log(intercoTransactionTotals)

  const allAnalysis = analyseIntercoTotals(intercoTransactionTotals)

  // Bring the analysis to the transaction level
  groupTransactions.forEach(function (transaction) {
    transaction.analysis = allAnalysis[transaction.intercoTransactionId]
  })

  return allAnalysis
}

function analyseIntercoTotals (intercoTransactionTotals) {
  const allAnalysis = {}

  // Issues when:
  // Only one entity has transactions
  // The value of the transactions is each entity is not the same
  // -> Need to go through the Map and identify these
  // Iterate through the Map to identify issues
  intercoTransactionTotals.forEach((intercoTransactionMap, intercoTransactionId) => {
    const analysis = {
      entitiesCount: intercoTransactionMap.size,
      issue: null, // 1: missing in one entity, 2: does not net to zero
      issueMessage: null,
      amounts: [null, null],
      dataObj: Object.fromEntries(intercoTransactionMap)
    }

    allAnalysis[intercoTransactionId] = analysis

    // Sum of all transaction sums for this intercoTransactionId
    const mapEntries = [...intercoTransactionMap.entries()]

    analysis.amounts[0] = mapEntries[0]?.[1]?.debits
    analysis.amounts[1] = mapEntries[1]?.[1]?.debits

    // Case 1: Only one entity has transactions
    if (analysis.entitiesCount !== 2) {
      analysis.issue = 1
      analysis.issueMessage = 'Missing in 1 entity'

      return
    }

    // Case 2: The value of the transactions is each entity is not the same
    if (analysis.amounts[0] !== analysis.amounts[1]) {
      analysis.issue = 2
      analysis.issueMessage = 'Different amounts'
    }
  })

  return allAnalysis
}
