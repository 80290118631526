import dayjs from 'dayjs'
import { makeXeroLink } from '../../helpers/xero-links.js'

export function makeColumnDefs (accountData, currencyResponse) {
  console.log(currencyResponse)

  const columnDefs = []

  columnDefs.push({
    field: 'name'
  })

  columnDefs.push({
    headerName: 'Xero',
    valueGetter: function (cellParams) {
      return makeXeroLink('Home', { shortCode: cellParams.data.xero_shortcode })
    },
    cellRenderer: function (cellParams) {
      return '<a href="' + cellParams.value + '" target="_blank">Open</a>'
    }
  })

  columnDefs.push({
    headerName: 'Currency',
    field: 'base_currency'
  })

  columnDefs.push({
    headerName: 'Financial Year',
    valueGetter: function (cellParams) {
      const dateIso = [dayjs().format('YYYY'), cellParams.data.year_end_month, cellParams.data.year_end_day].join('-')
      return dayjs(dateIso).format('DD MMM')
    },
    filterParams: {
      textFormatter: function (value) {
        return value === true ? 'Yes' : 'No'
      }
    }
  })

  columnDefs.push({
    headerName: 'Locked for team',
    valueGetter: function (cellParams) {
      if (!cellParams.data.lock_date_period) return null

      return dayjs(cellParams.data.lock_date_period).format('DD MMM YYYY')
    },
    cellRenderer: function (cellParams) {
      return showLockedDate(cellParams.data.lock_date_period, cellParams.data)
    },
    headerTooltip: 'In Xero: "Stop all users (except advisers) making changes on and before"',
    cellClass: ['is-flex', 'is-align-items-center'] // vertical align
  })

  columnDefs.push({
    headerName: 'Locked for all',
    valueGetter: function (cellParams) {
      if (!cellParams.data.lock_date_endofyear) return null

      return dayjs(cellParams.data.lock_date_endofyear).format('DD MMM YYYY')
    },
    cellRenderer: function (cellParams) {
      return showLockedDate(cellParams.data.lock_date_endofyear, cellParams.data)
    },
    headerTooltip: 'In Xero: "Stop all users making changes on and before"',
    cellClass: ['is-flex', 'is-align-items-center'] // vertical align
  })

  columnDefs.push({
    headerName: 'Xero-to-Xero',
    valueGetter: function (cellParams) {
      return cellParams.data.xero_to_xero
    },
    cellRenderer: function (cellParams) {
      const xeroUrl = makeXeroLink('XeroToXero', { shortCode: cellParams.data.xero_shortcode })

      return `<input type="text" class="input zen-vertical-align-baseline" value="${cellParams.data.xero_to_xero}"> &nbsp;<a href="${xeroUrl}" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a>`
    },
    cellClass: ['is-flex', 'is-align-items-center'], // vertical align
    width: 300
  })

  columnDefs.push({
    headerName: 'Legal Name',
    field: 'name_legal'
  })

  return columnDefs
}

function showLockedDate (xeroLockDate, xeroEntity) {
  const lockedDate = dayjs(xeroLockDate)
  const previousFinancialYear = dayjs(makePreviousFinancialYear(xeroEntity))

  const formattedDate = xeroLockDate
    ? lockedDate.format('D MMM YYYY')
    : ''

  const beforeFinancialYear = lockedDate.isBefore(previousFinancialYear)

  const recentDays = 60
  const beforeRecent = lockedDate.isBefore(dayjs().subtract(recentDays, 'days'))

  const xeroUrl = makeXeroLink('FinancialSettings', { shortCode: xeroEntity.xero_shortcode })

  let tag = ''

  if (!xeroLockDate) {
    tag = '<span class="tag is-warning is-light">Not locked</span>'
  } else if (beforeFinancialYear && beforeRecent) {
    tag = '<span class="tag is-danger">Before Financial Year</span>'
  } else if (beforeRecent) {
    tag = `<span class="tag is-warning">${recentDays}+ days ago</span>`
  }
  return `<a href="${xeroUrl}" target="_blank"><i class="fa-solid fa-up-right-from-square right-margin"></i></a> ${formattedDate} ${tag}`
}

function makePreviousFinancialYear (xeroEntity) {
  const dateIso = [dayjs().format('YYYY'), xeroEntity.year_end_month, xeroEntity.year_end_day].join('-')
  return dayjs(dateIso).subtract(1, 'year').format('YYYY-MM-DD')
}
