import { optionSelectorData } from '../data.js'

/**
*
* @returns
*/
export function updateSelectorEntity () {
  console.log('updateSelectorEntity', 'optionSelectorData', optionSelectorData)
  const selectorElement = document.getElementById('optionOrganisationSelector')
  // console.log('selectorElement', selectorElement)
  if (!selectorElement) return

  // Make all buttons not active and remove their icons
  const buttons = selectorElement.querySelectorAll('button')
  buttons.forEach(function (oneButton) {
    oneButton.classList.remove('is-active')

    const buttonIcon = oneButton.querySelector('.icon')
    buttonIcon.classList.add('is-hidden')
  })

  if (Array.isArray(optionSelectorData.organisationId)) {
    optionSelectorData.organisationId.forEach(function (orgId) {
      updateOneEntity(selectorElement, orgId)
    })
  } else {
    updateOneEntity(selectorElement, optionSelectorData.organisationId)
  }
}

function updateOneEntity (selectorElement, orgId) {
  console.log('updateOneEntity', selectorElement, orgId)
  // Make the button of the selected Org active and with the icon
  const selector = 'button[d-org-id=":orgId"]'.replace(':orgId', orgId)
  // console.log('selector', selector)
  const entityInUseElement = selectorElement.querySelector(selector)
  // console.log('entityInUseElement', entityInUseElement)
  if (!entityInUseElement) return
  entityInUseElement.classList.add('is-active')
  const iconElement = entityInUseElement.querySelector('.icon')
  iconElement.classList.remove('is-hidden')
}
